// src/components/ReactGaComponents.js
import ReactGA from "react-ga4";
import {useState} from "react";
import config from "./Config";

export const UserFirstInteractionGA = (props) => {
  const [interactions, setInteractions] = useState({});

  return () => {
    const key = `${props.action}-${props.label}`;
    if (!interactions[key]) {
      if (config.devMode) {
        console.log("UserInteractionGA: ", props.action, props.label);
      }
      ReactGA.event({
        category: 'Plot Interaction',
        action: props.action,
        label: props.label,
      });
      setInteractions({...interactions, [key]: true});
    }
  };
};


export const UserInteractionGA = (category, action, label) => {

  return () => {
    if (config.devMode) {
      console.log("UserInteractionGA: ", category, action, label);
    }
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };
};


export const UserInteractionZoomGA = (props) => {

  return () => {
    if (config.devMode) {
      console.log("UserInteractionGA: ", props.action, props.label);
    }
    ReactGA.event({
      category: 'Plot Interaction',
      action: props.action,
      label: props.label,
    });

  };
};
