import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography} from "@mui/material";
import {avg_range} from "../utils/ArrayFunctions";
import {styled} from "@mui/system";
import {TurnZoomRelative2Position} from "../utils/TurnZoomRelative2Position";
import {FormatReferenceData} from "../utils/FormatReferenceData";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function EvolutionMetrics(props) {

    const mdDown = useMediaQuery("(min-width:900px)")

    // Format Ref data:
    const reference = FormatReferenceData(props.reference, props.actuals.length)
    // Actuals ignoring Nulls
    const actuals = props.actuals.filter((value) => value !== null)
    const diff = actuals.map((value, index) => value - reference[index]);

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStart,
        props.zoomEnd,
        props.actuals.length);

    const StyledButton = styled(Button)(({theme}) => ({
        fontSize: '0.88rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.82rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.78rem',
        }
    }));

    const StyledButton2 = (props) => (
        <StyledButton
            sx={{
                pointerEvents: 'none!important',
                color: (theme) => theme.palette.text.primary,
                border: 2,
                borderColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                    border: 2,
                    borderColor: (theme) => theme.palette.primary.main,
                    background: 'none',
                },
                mb: '-1px',
            }}>
            {props.children}
        </StyledButton>
    );

    function StyledButton3(props) {
        return (
            <StyledButton2 key={props.keyValue}>
                {props.text}
                {props.value > 0 ? <ArrowUpwardIcon sx={{
                    fontSize: '0.92rem',
                    color: (theme) => theme.palette.secondary.main,
                    stroke: (theme) => theme.palette.secondary.main,
                    strokeWidth: 1,
                }}/> : <ArrowDownwardIcon sx={{
                    fontSize: '0.92rem',
                    color: (theme) => theme.palette.primary.main,
                    stroke: (theme) => theme.palette.primary.main,
                    strokeWidth: 1,
                }}/>} {props.value}
            </StyledButton2>
        )
    }

    const avgDeviation = avg_range(diff, startPosition, endPosition)

    // Define a new constant buttonsFlex similar to buttons but if avgDeviation is positive, ArrowUpwardIcon if negative, ArrowDownwardIcon
    const buttons = [
        <StyledButton3
            key="avgDeviation"
            keyValue={"deviation"}
            text={"Desviación media: "}
            value={avgDeviation}
        >
        </StyledButton3>,
    ];


    return (
        <Box
            sx={{
                display: 'flex',
                '& > *': {
                    m: 1,
                },
                justifyContent: "center",
            }}
            id="PeriodMetricsBox"
        >
            <ButtonGroup
                color="primary"
                orientation={mdDown ? `vertical` : `horizontal`}
            >
                <Typography sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "bold",
                    variant: "h5",
                    textAlign: "center",
                    pb: 2,
                    display: {md: 'block', xs: 'none'}
                }}> Periodo seleccionado
                </Typography>
                {buttons}
            </ButtonGroup>

        </Box>
    )
        ;
}

