import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography} from "@mui/material";
import {accSum, roundToDecimals} from "../utils/ArrayFunctions";
import {styled} from "@mui/system";
import {TurnZoomRelative2Position} from "../utils/TurnZoomRelative2Position";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {FormatReferenceData} from "../utils/FormatReferenceData";


export default function DailyMetrics(props) {
    const mdDown = useMediaQuery("(min-width:900px)");

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStart,
        props.zoomEnd,
        props.actuals.length);

    const reference = FormatReferenceData(props.reference, props.actuals.length)
    const actuals = props.actuals.filter(obj => obj !== null);  // Actual ignoring Nulls
    const diff = actuals.map((value, index) => value - reference[index]);

    const actualsSlice = actuals.slice(startPosition, endPosition);
    const diffSlice = diff.slice(startPosition, endPosition);

    const StyledButton = styled(Button)(({theme}) => ({
        fontSize: '0.88rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.82rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.78rem',
        }
    }));

    const StyledButton2 = (props) => (
        <StyledButton
            sx={{
                pointerEvents: 'none!important',
                color: (theme) => theme.palette.text.primary,
                border: 2,
                borderColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                    border: 2,
                    borderColor: (theme) => theme.palette.primary.main,
                    background: 'none',
                },
                mb: '-1px',
            }}>
            {props.children}
        </StyledButton>
    );

    function StyledButton3(props) {
        return (
            <StyledButton2 key={props.keyValue}>
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {props.text} {props.value}
                    </div>
                    <div>
                        {props.comValue > 0 ? <ArrowUpwardIcon sx={{
                            fontSize: '0.92rem',
                            color: (theme) => theme.palette.primary.main,
                            stroke: (theme) => theme.palette.primary.main,
                            strokeWidth: 1,
                        }}/> : <ArrowDownwardIcon sx={{
                            fontSize: '0.92rem',
                            color: (theme) => theme.palette.secondary.main,
                            stroke: (theme) => theme.palette.secondary.main,
                            strokeWidth: 1,
                        }}/>} {props.comValue}
                    </div>
                </div>
            </StyledButton2>
        )
    }

    // Write function StyledButton4 as StyledButton3 but adding a break line between the value and the comparison value:


    const buttons = [
        <StyledButton3
            key="prec diff"
            keyValue={"Prec Diff"}
            text={"Prec. Acc: "}
            value= {roundToDecimals(accSum(actualsSlice))[actualsSlice.length - 1]}
            comValue={roundToDecimals(accSum(diffSlice))[diffSlice.length - 1]}
        />,

    ];

    return (
        <Box
            sx={{
                display: 'flex',
                '& > *': {
                    m: 1,
                },
                justifyContent: "center",
            }}
            id="PeriodMetricsBox"
        >
            <ButtonGroup
                color="primary"
                orientation={mdDown ? `vertical` : `horizontal`}
            >
                <Typography sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "bold",
                    variant: "h5",
                    textAlign: "center",
                    pb: 2,
                    display: {md: 'block', xs: 'none'}
                }}> Periodo seleccionado
                </Typography>
                {buttons}
            </ButtonGroup>

        </Box>
    )
        ;
}

