import {styled} from "@mui/system";
import {Toolbar} from "@mui/material";

const SizeChangingToolbar = styled(Toolbar)(({theme, open}) => ({
        position: 'fixed',
        height: 70,
        width: open ? 'calc(100% - 240px)' : 'calc(100% - 72px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        zIndex: 100,
        //justifyContent: 'space-between',
    }))

export default SizeChangingToolbar;
