import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField} from '@mui/material';
import emailjs from 'emailjs-com';
import * as React from "react";
import Box from "@mui/material/Box";

export default function ContactDialog(props) {
    const [message, setMessage] = React.useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send('clima_observer', 'template_b7bvhob', {message: message}, 'mKz6sjCJw8mxJHcaZ')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        props.handleClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"¿Quieres contactar con nosotros?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Para cualquier duda, sugerencia o para comunicar un error, no dudes en contactar con nosotros.
                    Estaremos encantados
                    de ayudarte. <br/><br/>

                    Saludos, <br/>
                    Clima Observer
                </DialogContentText>
                <Box mt={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Tu mensaje"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={sendEmail}>Enviar</Button>
            </DialogActions>
        </Dialog>
    );
}