import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import MenuItems from "./MenuItems";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";

export default function HambDrawer(props) {
    const drawerWidth = 300; // Set your desired width here

    return (
        <SwipeableDrawer
            anchor="left"
            open={props.open}
            onOpen={() => props.setOpen(true)}
            onClose={() => props.setOpen(false)}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                    width: drawerWidth, // Apply the custom width
                },
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton
                    onClick={() => props.setOpen(false)}
                >
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>
            <MenuItems
                setMode={props.setMode}
                mode={props.mode}
                setPageSelected={props.setPageSelected}
                open={props.open}
            />
        </SwipeableDrawer>
    )
}
