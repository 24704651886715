// Check if 2 dates passed as string with format YYYY-MM-DD format show the same year

function isSameYear(date1, date2) {
  // First check if the dates are valid
    if (!date1 || !date2) {
        return false;
    }
  // Then check if the years are the same
  return date1.split("-")[0] === date2.split("-")[0];
}

export default isSameYear;