import {lightTheme} from "../../../styles/lightTheme";

var opacity = 0.9;
var colorPalette = [
    '#005eaa',
    '#2b821d',
    '#e6b600',
    '#c12e34',
    '#0098d9',
    '#32a487',
    '#339ca8',
    '#cda819',
];

var light_style = {
        color: colorPalette,

        title: {
            textStyle: {
                fontWeight: 'normal'
            }
        },

        visualMap: {
            color: ['#1790cf', '#a2d4e6']
        },

        toolbox: {
            iconStyle: {
                borderColor: '#06467c'
            }
        },

        tooltip: {
            backgroundColor: 'rgb(255,255,255)'
        },

        dataZoom: {
            dataBackgroundColor: '#dedede',
            fillerColor: 'rgba(154,217,247,0.2)',
            handleColor: '#005eaa'
        },

        timeline: {
            lineStyle: {
                color: '#005eaa'
            },
            controlStyle: {
                color: '#005eaa',
                borderColor: '#005eaa'
            }
        },

        bar: {
            opacity: opacity,
        },

        candlestick: {
            itemStyle: {
                color: lightTheme.palette.plot.primary,
                color0: lightTheme.palette.plot.primary,
                borderColor: lightTheme.palette.text.primary,
                borderColor0: lightTheme.palette.text.primary,
                opacity: opacity,
                borderWidth: 0
            },
            lineStyle: {
                width: 1,
                color: '#c12e34',
                color0: '#2b821d',
            },
            areaStyle: {
                color: '#e6b600',
                color0: '#005eaa'
            }
        },
        line: {
            symbol: 'circle'
        },
        graph: {
            itemStyle: {
                color: '#e6b600'
            },
            linkStyle: {
                color: '#005eaa'
            }
        },
        effectScatter: {
            itemStyle: {
                // square
                opacity: opacity
            }
        },
        map: {
            itemStyle: {
                color: '#f2385a',
                borderColor: '#eee',
                areaColor: '#ddd'
            },
            areaStyle: {
                color: '#ddd'
            },
            label: {
                color: '#c12e34'
            }
        },

        gauge: {
            color: [lightTheme.palette.secondary.main, lightTheme.palette.primary.main],

            progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                    borderWidth: 1,
                    borderColor: '#464646',
                    opacity: opacity
                }
            },
            axisLine: {
                lineStyle: {
                    width: 20,
                    color: [[1, "#E6F2F8"]]
                }
            },
            splitLine: {
                show: false,
                distance: 0,
                length: 10
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false,
                distance: 50
            },
            title: {
                // Titles
                fontSize: 13,
                fontFamily: "Roboto",
                fontWeight: "bold",
            },
            detail: {
                // Values
                width: 50,
                height: 14,
                fontSize: 14,
                color: 'inherit',
                borderColor: 'inherit',
                borderRadius: 20,
                borderWidth: 1,
                fontFamily: "Roboto"
            }
        }
    }
;

export default light_style;