const roundDecimals = 2;

// Extract max value from a list
function max_range(list, start, end) {
    return Math.max.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals);
};

// Extract min value from a list ignoring nulls from element start to end passed as parameters
function min_range(list, start, end) {
    return Math.min.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals);
};

// compute average value from a list  rounded to 2 decimals from element start to end passed as parameters
function avg_range(list, start, end) {
    // check if start and end are defined
    const non_null_list = list.slice(start, end).filter((x) => x != null);
    return (non_null_list.reduce((a, b) => a + b) / non_null_list.length).toFixed(roundDecimals);
};

// takes the difference between end and start and multiplies by tmpSlope. Return this value in absolute value
function slope_range(start, end, tmpSlope) {
    return Math.abs((end - start) * tmpSlope).toFixed(roundDecimals);
};

// Extract larger absolute value with its sign
function max_abs_range(list, start, end) {
    const max = Math.max.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals) ;
    const min = Math.min.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals) ;
    return Math.abs(max) > Math.abs(min) ? max : min;
};

// Extract larger absolute value without sign
function max_abs_range_no_sign(list, start, end) {
    const max = Math.max.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals) ;
    const min = Math.min.apply(Math, list.slice(start, end).filter((x) => x != null)).toFixed(roundDecimals) ;
    return Math.abs(max) > Math.abs(min) ? Math.abs(max) : Math.abs(min);
};

// Receive 2 integers and return the larger absolute value with no sign
function max_abs(a, b) {
    return Math.abs(a) > Math.abs(b) ? Math.abs(a) : Math.abs(b);
};

function accAvg(array) {
    let acc = 0;
    // Filter array only taking values != null
    array = array.filter(value => value != null);

    return array.map((value, index) => {
        acc += value;
        return Math.round((acc / (index + 1)) * 100) / 100;
    });
}

// Redo accSum in a way that if it receives [Null, Null, ... , value1, values2, ...] it will return [Null, Null, ... , value1, value1 + value2, ...]

function accSum(array) {
    let acc = 0;
    // Filter array only taking values != null
    return array.map((value, index) => {
        if (value != null) {
            acc += value;
        }
        else {
            acc = null;
        }
        return acc;
    });
}

// Function rounding to decimals an array respecting Null values and 0s
function roundToDecimals(array) {
    return array.map((value) => {
        if (value != null) {
            return Math.round(value * Math.pow(10, 2), roundDecimals) / Math.pow(10, 2);
        }
        else {
            return null;
        }
    });
}


export {
    max_range, min_range, avg_range, slope_range, max_abs_range, max_abs_range_no_sign,
    max_abs, accAvg, accSum, roundToDecimals
};