// Function returning the max and its position in an array. The returned format is [position, max]
function maxPos(array) {
    let max = array[0];
    let position = 0;
    for (let i = 1; i < array.length; i++) {
        if (array[i] > max) {
            max = array[i];
            position = i;
        }
    }
    return [position, max];
}

// Function returning the min and its position in an array. The returned format is [position, min]
function minPos(array) {
    // Generate an array where Null values in array are replaced by 999 (trick to avoid Nulls
    // considered as Nulls and therefore the min)
    let array_f = array.map(function (item) {
        if (item === null) {
            return 999;
        } else {
            return item;
        }
    });

    let min = array_f[0];
    let position = 0;
    for (let i = 1; i < array_f.length; i++) {
        if (array_f[i] < min) {
            min = array_f[i];
            position = i;
        }
    }
    return [position, min];
}

// Passed 2 arrays, function returning the positions and maximum values where the values from the first array are
// greater than the values from the second array. The returned format is [[position, max], [position, max], ...]

function maxPosArray(array1, array2) {
    let maxPosArray = [];
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] > array2[i]) {
            maxPosArray.push([i, array1[i]]);
        }
    }
    return maxPosArray;
}

// Passed 2 arrays, function returning the positions and minimum values where the values from the first array are
// smaller than the values from the second array. The returned format is [[position, min], [position, min], ...]

function minPosArray(array1, array2) {
    let minPosArray = [];
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] < array2[i]) {
            minPosArray.push([i, array1[i]]);
        }
    }
    return minPosArray;
}

export {maxPos, minPos, maxPosArray, minPosArray};