import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import {Drawer} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuItems from "./MenuItems";


export default function DrawerCustom(props) {

    const drawerWidth = 280;

    const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
        }),
    );

    // For a reason difficult to understand, just importing MenuItems does not work
    return (
        <Drawer variant="permanent" open={props.open} sx={{display: {xs: 'none', sm: 'block'}}}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                    height: 70,
                }}
            >
                <IconButton onClick={e => props.setOpen(!props.open)}>
                    {props.open ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </Toolbar>
            <Divider/>
            <MenuItems
                setPageSelected={props.setPageSelected}
                setMode={props.setMode}
                mode={props.mode}
                open={props.open}
            />
        </Drawer>)
}
