export function removeAddColumn(props){
        if (props.smDown) {
        for (let i = 0; i < props.headCells.length; i++) {
            if (props.headCells[i].id === 'region') {
                props.headCells.splice(i, 1);
            }
        }
        /* if props.smDown is false and id region is not in headCells, add it in second place as {id: 'region',align: "center",label: 'Provincia'} */
    } else if (!props.smDown) {
        let region = {id: 'region',align: "center",label: 'Provincia'};
        let found = false;
        for (let i = 0; i < props.headCells.length; i++) {
            if (props.headCells[i].id === 'region') {
                found = true;
            }
        }
        if (!found) {
            props.headCells.splice(2, 0, region);
        }
    }

    return props.headCells;
}