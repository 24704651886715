import {createTheme, responsiveFontSizes} from '@mui/material/styles';

export const darkTheme = responsiveFontSizes(
    createTheme({
            palette: {
                mode: "dark",
                primary: {
                    main: '#4992ff',
                    light: '#4992FF80'
                },
                secondary: {
                    main: '#ff6e76'
                },

                background: {
                    // '#121212', '#1e1e1e', '#2a2a2a'
                    default: '#1e1e1e',
                    paper: '#121212',
                    tables: '#121212', // '#171717',
                },

                temperatures: {
                    min: '#af7af6',
                    avg_min: '#7cffb2',
                    avg: '#75b8dc',
                    avg_max: '#fddd60',
                    max: '#ff6e76',
                },
                precipitation: {
                    moreThanHist: "rgba(117,184,220,0.5)",
                    lessThanHist: "rgba(255,110,118,0.5)"
                },
                plot: {
                    primary: '#4992ff',
                    // 4961ff
                    secondary: '#d7662e'
                },
                legend: {
                    inactive: '#464646',
                },
                scatter: {
                    max: '#e35156',
                    min: '#75b8dc',
                    max_hist: '#e3f1f6',
                    min_hist: '#e3f1f6',
                },
                text: {
                    primary: "rgb(185,184,204)",
                },
                plots: {
                    candle_avg: "rgb(185,184,204)"
                }
            },

            typography: {
                button: {
                    textTransform: 'none'
                }
            },

        }
    ))
