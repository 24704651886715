import * as React from 'react';
import {useState} from "react";

// Material UI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import {ListItem, Switch} from "@mui/material";
import Box from "@mui/material/Box";

// Icons
import TimelineIcon from '@mui/icons-material/Timeline';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import EmailIcon from '@mui/icons-material/Email';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import {DarkMode} from "@mui/icons-material";
import ContactDialog from "./ContactDialog";
import MenuLogo from "../logo/MenuLogo";

export default function MenuItems(props) {
    const smDown = useMediaQuery("(max-width:600px)");
    const [checked, setChecked] = useState(props.mode === "light" ? false : true);
    const [openContact, setOpenContact] = React.useState(false);
    const fontSizeMenu = smDown ? "14px" : "17px";

    const switchHandler = (event) => {
        props.setMode((state) => (state === "light" ? "dark" : "light"))
        setChecked(event.target.checked)
    };

    const handleContactOpen = () => {
        setOpenContact(true);
    };

    const handleContactClose = () => {
        setOpenContact(false);
    };


    return (
        <List component="nav">
            <ListItemButton onClick={() => props.setPageSelected('Histórico')}>
                <ListItemIcon sx={{marginTop: 2}}>
                    <TimelineIcon/>
                </ListItemIcon>
                <ListItemText sx={{marginTop: 2}}
                              primary="Histórico"
                              primaryTypographyProps={{fontSize: fontSizeMenu}}
                />
            </ListItemButton>

            <ListItemButton onClick={() => props.setPageSelected('Temperatura')}>
                <ListItemIcon>
                    <ThermostatIcon/>
                </ListItemIcon>
                <ListItemText
                    primary="Temperatura"
                    primaryTypographyProps={{fontSize: fontSizeMenu}}
                />
            </ListItemButton>

            <ListItemButton onClick={() => props.setPageSelected('Precipitación')}>
                <ListItemIcon sx={{marginBottom: 2}}>
                    <ThunderstormIcon/>
                </ListItemIcon>
                <ListItemText sx={{marginBottom: 2}}
                              primary="Precipitación"
                              primaryTypographyProps={{fontSize: fontSizeMenu}}
                />
            </ListItemButton>

            <Divider sx={{my: 1}}/>

            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon onClick={switchHandler}>
                        <DarkMode/>
                    </ListItemIcon>
                    <ListItemText
                        primary="Modo oscuro"
                        primaryTypographyProps={{fontSize: fontSizeMenu}}
                    />
                    <Switch
                        checked={checked}
                        onChange={switchHandler}
                    />
                </ListItemButton>
            </ListItem>

            <ListItemButton onClick={handleContactOpen}>
                <ListItemIcon sx={{marginBottom: 2}}>
                    <EmailIcon/>
                </ListItemIcon>
                <ListItemText sx={{marginBottom: 2}}
                              primary="Contacto"
                              primaryTypographyProps={{fontSize: fontSizeMenu}}
                />
            </ListItemButton>

            <Divider sx={{my: 1}}/>

            {props.open && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 5
                    }}
                >
                    <MenuLogo alt="Logo"/>
                </Box>
            )}

            <ContactDialog open={openContact} handleClose={handleContactClose}/>

        </List>
    );
}
