import * as React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography} from "@mui/material";
import {max_range, min_range, avg_range} from "../utils/ArrayFunctions";
import {styled} from "@mui/system";
import {TurnZoomRelative2Position} from "../utils/TurnZoomRelative2Position";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {FormatReferenceData} from "../utils/FormatReferenceData";


export default function DailyMetrics(props) {
    const reference = FormatReferenceData(props.reference, props.actuals.length)
    // Actuals ignoring Nulls
    const actuals = props.actuals.filter(obj => obj.tmin !== null && obj.tmed !== null && obj.tmax !== null);

    const mdDown = useMediaQuery("(min-width:900px)");

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStart,
        props.zoomEnd,
        props.actuals.length);

    // Variable definition:
    /// Actual:
    const actual_tmin = actuals.map(obj => obj.tmin);
    const actual_tmed = actuals.map(obj => obj.tmed);
    const actual_tmax = actuals.map(obj => obj.tmax);
    /// Reference:
    const ref_hist_min = reference.map(obj => obj.min_temp);
    const ref_tmin = reference.map(obj => obj.avg_min_temp);
    const ref_tmed = reference.map(obj => obj.avg_temp);
    const ref_tmax = reference.map(obj => obj.avg_max_temp);
    const ref_hist_max = reference.map(obj => obj.max_temp);
    /// Actual - Reference (Diff):
    const tmin_diff = actual_tmin.map((value, index) => value - ref_tmin[index]);
    const tmed_diff = actual_tmed.map((value, index) => value - ref_tmed[index]);
    const tmax_diff = actual_tmax.map((value, index) => value - ref_tmax[index]);

    // Variability: tmax - tmin
    // const tvar = tmax.map((value, index) => value - tmin[index]);

    const StyledButton = styled(Button)(({theme}) => ({
        fontSize: '0.88rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.82rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.78rem',
        }
    }));

    const StyledButton2 = (props) => (
        <StyledButton
            sx={{
                pointerEvents: 'none!important',
                color: (theme) => theme.palette.text.primary,
                border: 2,
                borderColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                    border: 2,
                    borderColor: (theme) => theme.palette.primary.main,
                    background: 'none',
                },
                mb: '-1px',
            }}>
            {props.children}
        </StyledButton>
    );

    function StyledButton3(props) {
        return (
            <StyledButton2 key={props.keyValue}>
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        {props.text} {props.value}
                    </div>
                    <div>
                        {props.comValue > 0 ? <ArrowUpwardIcon sx={{
                            fontSize: '0.92rem',
                            color: (theme) => theme.palette.secondary.main,
                            stroke: (theme) => theme.palette.secondary.main,
                            strokeWidth: 1,
                        }}/> : <ArrowDownwardIcon sx={{
                            fontSize: '0.92rem',
                            color: (theme) => theme.palette.primary.main,
                            stroke: (theme) => theme.palette.primary.main,
                            strokeWidth: 1,
                        }}/>} {props.comValue}
                    </div>
                </div>
            </StyledButton2>
        )
    }

    // Write function StyledButton4 as StyledButton3 but adding a break line between the value and the comparison value:


    const buttons = [
        /*
        <StyledButton3
            key="min temperature"
            keyValue={"min"}
            text={"Mínima: "}
            value={min_range(actual_tmin, startPosition, endPosition)}
            comValue={(min_range(actual_tmin, startPosition, endPosition) - min_range(ref_hist_min, startPosition, endPosition)).toFixed(2)}
        />,
        */
        <StyledButton3
            key="avg min temperature"
            keyValue={"min avg"}
            text={"Med. mínimas: "}
            value={avg_range(actual_tmin, startPosition, endPosition)}
            comValue={avg_range(tmin_diff, startPosition, endPosition)}
        />,
        <StyledButton3
            key="avg temperature"
            keyValue={"avg"}
            text={"Media: "}
            value={avg_range(actual_tmed, startPosition, endPosition)}
            comValue={avg_range(tmed_diff, startPosition, endPosition)}
        />,
        <StyledButton3
            key="avg max temperature"
            keyValue={"max avg"}
            text={"Med. máximas: "}
            value={avg_range(actual_tmax, startPosition, endPosition)}
            comValue={avg_range(tmax_diff, startPosition, endPosition)}
        />,
        /*
        <StyledButton3
            key="max temperature"
            keyValue={"max"}
            text={"Mínima: "}
            value={min_range(actual_tmax, startPosition, endPosition)}
            comValue={(max_range(actual_tmax, startPosition, endPosition) - max_range(ref_hist_max, startPosition, endPosition)).toFixed(2)}
        />
         */
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                '& > *': {
                    m: 1,
                },
                justifyContent: "center",
            }}
            id="PeriodMetricsBox"
        >
            <ButtonGroup
                color="primary"
                orientation={mdDown ? `vertical` : `horizontal`}
            >
                <Typography sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "bold",
                    variant: "h5",
                    textAlign: "center",
                    pb: 2,
                    display: {md: 'block', xs: 'none'}
                }}> Periodo seleccionado
                </Typography>
                {buttons}
            </ButtonGroup>

        </Box>
    )
        ;
}

