import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import {lightTheme} from "../../../styles/lightTheme";
import {darkTheme} from "../../../styles/darkTheme";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {TurnZoomRelative2Position} from "../../utils/TurnZoomRelative2Position";
import {accSum, roundToDecimals} from "../../utils/ArrayFunctions";
import {AddNulls2ReferenceEnd} from "../../precipitation/PrecipitationDataFormat";
import {markerFormatter} from "../../utils/MarkerFormatter";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

function PrecMainPlot(props) {
    const theme = props.mode === 'light' ? lightTheme : darkTheme
    const dasahedMarker = 'path://M5 0 l 80 0 a 20 20 0 0 1 20 20 l 0 80 a 20 20 0 0 0 20 20 l 80 0 a 20 20 0 0 0 20 -20 l 0 -80 a 20 20 0 0 1 20 -20 l 80 0 a 20 20 0 0 1 20 20 l 0 80 a 20 20 0 0 0 20 20 l 80 0 a 20 20 0 0 0 20 -20 l 0 -80 a 20 20 0 0 1 20 -20 l 80 0 a 20 20 0 0 1 20 20 l 0 80 a 20 20 0 0 0 20 20 l 80 0 a 20 20 0 0 0 20 -20 l 0 -80 a 20 20 0 0 1 20 -20'

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStartDaily,
        props.zoomEndDaily,
        props.dates.length);

    const legendShow = props.smDown
        ? ["Diaria", "Acumulada", "Ref. hist."]
        : ["Diaria", "Acumulada", "Referente histórico"];

    const legendStatus = props.smDown
        ? {"Acumulada": true, "Ref. hist.": true, "Diaria": true}
        : {"Acumulada": true, "Referente histórico": true, "Diaria": true};

    function legendNameTransform(legendName) {
        const nameMap = {
            "Diaria": "Diaria",
            "Acumulada": "Acumulada",
            "Referente histórico": "Ref. hist."
        };

        const reverseNameMap = {
            "Diaria": "Diaria",
            "Acumulada": "Acumulada",
            "Ref. hist.": "Referente histórico",
        };

        if (props.smDown) {
            return nameMap[legendName] || legendName;
        } else {
            return reverseNameMap[legendName] || legendName;
        }
    }

    legendStatus[legendNameTransform("Diaria")] = props.dailyLegendFlag;
    legendStatus[legendNameTransform("Acumulada")] = props.actLegendFlag;
    legendStatus[legendNameTransform("Referente histórico")] = props.refLegendFlag;

    // 2 Main Series
    const referenceData = roundToDecimals(accSum(props.refData.slice(startPosition, endPosition)))
    const precValues = roundToDecimals(accSum(props.actualData.slice(startPosition, endPosition)))

    // Area Series
    const diff = referenceData.map((v, i) => precValues[i] - v)
    const underReference = referenceData.map((v, i) => v > precValues[i] ? diff[i] : null)
    const overReference = referenceData.map((v, i) => v < precValues[i] ? diff[i] : null)

    const overReferenceNulled = AddNulls2ReferenceEnd(overReference, precValues)
    const underReferenceNulled = AddNulls2ReferenceEnd(underReference, precValues)

    const unitsString = props.smDown ? "" : " L/m²"


    const options = {

            // set background color  to transparent
            backgroundColor: "transparent",

            tooltip: {
                // Add the hover legend
                trigger: "axis", // Vertical line selector
                axisPointer: {
                    type: "cross", // Also adds the horizontal line
                },
                formatter: function (params) {
                    let historicString = "";
                    let actualString = "";

                    params.forEach(param => {
                        if (param.value !== undefined) {
                            if (["Menos Prec. que Historico", "Más Prec. que Historico", "Referente histórico"].includes(param.seriesName)) {
                                historicString += markerFormatter({
                                    color: param.color,
                                    type: ["Menos Prec. que Historico", "Más Prec. que Historico"].includes(param.seriesName) ? 'effectScatter' : param.seriesType
                                }) + (param.seriesName !== "Referente histórico" ? '&nbsp;Diff. Acumulada: ' + param.data.toFixed(2) + " L/m² <br/>&nbsp;&nbsp;&nbsp;(Actual vs Historico)" : param.seriesName + ': ' + param.data.toFixed(2) + " L/m² <br/>");
                            } else if (["Diaria", "Acumulada"].includes(param.seriesName)) {
                                actualString += markerFormatter({
                                    color: param.color,
                                    type: param.seriesType
                                }) + param.seriesName + ': ' + param.data.toFixed(2) + " L/m² <br/>";
                            }
                        }
                    });

                    return [
                        params[0].name + "<br/>",
                        actualString ? '<hr size=1 style="margin: 3px 0"> <b> Prec. actuales </b> <br/>' + actualString : '',
                        historicString ? '<hr size=1 style="margin: 3px 0"> <b> Prec. históricas </b> <br/> ' + historicString : '',
                    ].join('');
                }
            },

            xAxis: {
                type: "category",
                data:
                    props.dates.slice(startPosition, endPosition),
                axisLabel:
                    {
                        show: false
                    }
                ,
                axisTick: {
                    show: false
                }
                ,
                // dont show the axis line
                axisLine: {
                    show: false
                }
                ,
            }
            ,
            yAxis: {
                type: "value",
                min:

                    function (value) {
                        const max = value.min - 4.9;
                        const maxDivided = max / 10;
                        const valueRounded = maxDivided.toFixed(0);
                        return valueRounded * 10;
                    }

                ,
                max: function (value) {
                    const max = value.max + 10;
                    const maxDivided = max / 10;
                    const valueRounded = maxDivided.toFixed(0);
                    return valueRounded * 10;
                }
                ,
                axisLabel: {
                    formatter: '{value}' + unitsString,
                    // Do not show , if number >= 1000
                    show:
                        true,
                }
            }
            ,

            grid: [
                {
                    left: props.smDown ? 40 : props.mdDown ? 70 : 70, // Stations with lot of precipitation
                    right: props.smDown ? 10 : props.mdDown ? 60 : 60,
                    top: 40,
                    height: 245,
                    bottom: 5
                }
            ],

            legend:
                {
                    data: [
                        {
                            name: legendShow[0],
                            icon: 'path://M0 0 l 0 40 40 0 40 0 0 -40 -40 0z'
                        },
                        {
                            name: legendShow[1],
                            icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
                        },
                        {
                            name: legendShow[2],
                            icon: dasahedMarker
                        },

                    ],
                    type:
                        'scroll',
                    top:
                        10,
                    bottom:
                        0,
                    itemGap:
                        30,
                    textStyle:
                        {
                            fontSize: 12,
                            fontWeight:
                                "bold",
                            fontFamily:
                                "Roboto"
                        }
                    ,
                    // Change legend size
                    itemWidth: 15,
                    selected:
                    legendStatus,
                }
            ,

            series: [
                {
                    type: 'bar',
                    name: 'Diaria',
                    data: props.actualData.slice(startPosition, endPosition),
                    color: theme.palette.primary.main
                },
                {
                    type: 'line',
                    name: 'Acumulada',
                    showSymbol: false,
                    smooth: true,
                    color: theme.palette.primary.main,
                    data: precValues
                },
                {
                    type: 'line',
                    name: props.smDown ? 'Ref. hist.' : 'Referente histórico',
                    lineStyle: {
                        color: theme.palette.text.primary,
                        width: 1,
                        type: "dashed"
                    },
                    showSymbol: false,
                    smooth: true,
                    color: theme.palette.text.primary,
                    stack: 'x',
                    data: referenceData
                },
                {
                    type: 'line',
                    name: 'Menos Prec. que Historico',
                    showSymbol: false,
                    smooth: true,
                    color: theme.palette.precipitation.lessThanHist,
                    stack: 'x',
                    stackStrategy: 'positive',
                    lineStyle: {
                        opacity: 0
                    },
                    symbol: 'none',
                    areaStyle: {
                        color: theme.palette.precipitation.lessThanHist
                    },
                    tooltip: {
                        show: true
                    },
                    data: underReferenceNulled
                },
                {
                    type: 'line',
                    name: 'Más Prec. que Historico',
                    showSymbol: false,
                    smooth: true,
                    color: theme.palette.precipitation.moreThanHist,
                    stack: 'x',
                    stackStrategy: 'positive',
                    lineStyle: {
                        opacity: 0
                    },
                    symbol: 'none',
                    areaStyle: {
                        color: theme.palette.precipitation.moreThanHist
                    },
                    tooltip: {
                        show: true
                    },
                    data: overReferenceNulled
                }
            ]
        }
    ;

    function onChartLegendselectchanged(param) {
        if (param.name === "Acumulada") {
            props.setActLegendFlag(!props.actLegendFlag)
        } else if (param.name === "Ref. hist." || param.name === "Referente histórico") {
            props.setRefLegendFlag(!props.refLegendFlag)
        } else if (param.name === "Diaria") {
            props.setDailyLegendFlag(!props.dailyLegendFlag)
        }
    }
    ;


    return (
        <ReactEcharts
            option={options}
            theme={props.mode}
            style={{height: "290px", width: "100%"}}
            onEvents={{
                'legendselectchanged': onChartLegendselectchanged
            }}
        />
    );
}

export default PrecMainPlot;
