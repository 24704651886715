import {createTheme, responsiveFontSizes} from '@mui/material/styles';

const primary = "#0e80d0"

export const lightTheme = responsiveFontSizes(
    createTheme({
            palette: {
                primary: {
                    main: primary,
                    light: "#b5cce3",
                    dark: "#002884",
                    contrastText: "#fafafa",
                },
                secondary: {
                    main: "#f44336",
                    light: "#ff7961",
                    dark: "#ba000d",
                    contrastText: "#000",
                },
                background: {
                    default: "#f8f8f8",
                    paper: "#f8f8f8",
                    tables: "#f8f8f8", //#ffffff
                },
                temperatures: {
                    min: '#442aad',
                    avg_min: '#63bb29',
                    avg: '#94d3ff',
                    avg_max: '#f3a135',
                    max: '#a12401',
                },
                precipitation: {
                    moreThanHist: "rgba(176,221,252,0.5)", // temperatures avg 40%
                    lessThanHist: "rgba(255,121,97,0.5)", // Secondary light 40%
                },
                plot: {
                    primary: primary,
                    secondary: '#f37735'
                },
                legend: {
                    inactive: '#afafaf',
                },
                scatter: {
                    max: '#e35156',
                    min: '#94d3ff',
                    max_hist: '#303034',
                    min_hist: '#303034',
                },
                text: {
                    primary: "rgb(37,37,37)",
                },
                plots: {
                    candle_avg: "#efefef"
                }
            },
            typography: {
                fontFamily: 'Roboto',
                button: {
                    textTransform: 'none'
                }
            },
        }
    ))
