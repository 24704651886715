import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import ReactGA from 'react-ga4';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography, Stack, Box, Hidden, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import config from "../Config";
import { lightTheme } from "../../styles/lightTheme";
import { darkTheme } from "../../styles/darkTheme";
import SearchRegion from "./SearchRegion";
import SearchStation from "./SearchStation";
import DrawerCustom from "./Drawer";
import SizeChangingBox from "./SizeChangingBox";
import SizeChangingToolbar from "./SizeChangingToolbar";
import Source from "./Source";
import HambDrawer from "./HambDrawer";
import ToolBarLogo from "../logo/ToolBarLogo";
import CookieConsent from './CookieConsent';
import { useThemeContext } from "../../store/StoreVariables";

export default function GeneralStructure(props) {
  const sendRegionToGoogle = (regionName) => {
    if (initialLoadComplete) {
      ReactGA.event({
        category: 'User Iteration',
        action: 'Selected Region',
        label: regionName,
      });
    }
  }

  const sendStationToGoogle = (stationName) => {
    if (initialLoadComplete) {
      ReactGA.event({
        category: 'User Iteration',
        action: 'Selected Station',
        label: stationName,
      });
    }
  }

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const lgDown = useMediaQuery("(max-width:1200px)");
  const smDown = useMediaQuery("(max-width:600px)");

  // UI
  const [open, setOpen] = useState(!lgDown);
  const [openHamburger, setOpenHamburger] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (lgDown) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [lgDown]);

  useEffect(() => {
    setInitialLoadComplete(true);
  }, []);

  const {
    mode,
    setMode,
    setStationName,
    regionName,
    setRegionName,
    stationMenuName,
    setStationMenuName
  } = useThemeContext();

  const stationsToShow = useQuery({
    queryKey: ['stationosToShow', "stationsToShow"],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = config.apiUrl + "stations_to_show/";
      if (config.devMode) {
        console.log("GET Stations: " + getRequest);
      }
      return fetch(getRequest).then((response) => response.json());
    }
  });

  const getStations = (props) => {
    let stations = [];
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i].district === props.regionName) {
        stations.push(props.data[i].name);
      }
    }
    return stations;
  }

  const getDistricts = (props) => {
    let districts = [];
    for (let i = 0; i < props.data.length; i++) {
      if (!districts.includes(props.data[i].district)) {
        districts.push(props.data[i].district);
      }
    }
    return districts;
  }

  return (
    <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <DrawerCustom
          setPageSelected={props.setPageSelected}
          setOpen={setOpen}
          open={open}
          setMode={setMode}
          mode={mode}
        />
        <HambDrawer
          setOpen={setOpenHamburger}
          open={openHamburger}
          setMode={setMode}
          mode={mode}
          setPageSelected={props.setPageSelected}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <SizeChangingToolbar open={open}>
            <Hidden smUp>
              <IconButton
                onClick={() => setOpenHamburger(!openHamburger)}
                sx={{
                  padding: '8px',
                  margin: '0',
                  marginRight: '14px',
                  color: 'white',
                }}
              >
                <MenuIcon
                  sx={{
                    color: 'inherit',
                  }}
                />
              </IconButton>
            </Hidden>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                paddingLeft: isMdUp ? '1%' : '0',
                flexGrow: 1,
              }}
            >
              <ToolBarLogo alt="Logo" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: isMdUp ? '13%' : '0',
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                color="white"
              >
                {props.pageSelected}
              </Typography>
            </Box>
          </SizeChangingToolbar>

          <SizeChangingBox open={open} mode={mode} sx={{
            backgroundColor: (theme) => theme.palette.background.default
          }}>
            <Stack spacing={2} direction="row" justifyContent="center">
              {stationsToShow.data &&
                <SearchRegion
                  sx={{ width: '35%' }}
                  darray={getDistricts({ data: stationsToShow.data })}
                  setRegionName={(region) => {
                    setRegionName(region);
                    sendRegionToGoogle(region);
                  }}
                  setStationMenuName={setStationMenuName}
                  regionName={regionName}
                />}
              {stationsToShow.data &&
                <SearchStation
                  sx={{ width: '35%' }}
                  darray={getStations({ data: stationsToShow.data, regionName: regionName })}
                  setStationName={setStationName}
                  setStationMenuName={(station) => {
                    setStationMenuName(station);
                    sendStationToGoogle(station);
                  }}
                  stationMenuName={stationMenuName}
                />}
            </Stack>
          </SizeChangingBox>
          <Container
            maxWidth="lg"
            sx={{
              mt: 22,
              mb: 4,
            }}
          >
            {props.children}
            <Source sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <CookieConsent
        open={open}
        mode={mode}
        smDown={smDown}
      />
    </ThemeProvider>
  );
}
