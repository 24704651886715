function TurnZoomRelative2Position(zoomStart, zoomEnd, dateLength) {
    const startPosition = (zoomStart / 100 * (dateLength - 1)).toFixed(0);
    const endPosition = (zoomEnd / 100 * (dateLength - 1)).toFixed(0);

    return [startPosition, parseInt(endPosition) + 1]
};

// Same function but only returning endPosition
function TurnZoomRelative2PositionEnd(zoomEnd, dateLength) {
    const endPosition = (zoomEnd / 100 * (dateLength - 1)).toFixed(0);

    return parseInt(endPosition) + 1
};

export {TurnZoomRelative2Position, TurnZoomRelative2PositionEnd};