import {Typography} from "@mui/material";

const PageTitle = (props) => (
    <Typography
        variant="h6"
        sx={{
            mt: 1,
            mb: 1,
            fontWeight: 'bold',
            color: (theme) => theme.palette.primary.main
        }}>
        {props.children}
    </Typography>
);

const PlotTitle = (props) => (
    <Typography
        variant="h6"
        sx={{
            mt: 1,
            mb: 1,
            fontWeight: 'bold',
            color: (theme) => theme.palette.primary.main
        }}>
        {props.children}
    </Typography>
);

export {PageTitle, PlotTitle};