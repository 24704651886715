import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import LinearRegression from "../../utils/LinearRegression";
import MarkLineGenerator from "../../utils/MarkLineGenerator";

// Styles
import {lightTheme} from "../../../styles/lightTheme";
import {darkTheme} from "../../../styles/darkTheme";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {maxPos, minPos} from "../../utils/ArrayPositionFunctions";
import {markerFormatter} from "../../utils/MarkerFormatter";
import {UserInteractionGA} from "../../ReactGaComponents";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

let zoomLogged = false;

function TempPlot(props) {
  const theme = props.mode === 'light' ? lightTheme : darkTheme
  const unitsString = props.smDown ? "" : " °C";

  // Time Series:
  const dateList = props.data.map(obj => obj["year"]);

  // Data
  /// Raw:
  const MinData = props.data.map(obj => obj["tmin_min"])
  const MinAvgData = props.data.map(obj => obj["tmin_avg"])
  const AvgData = props.data.map(obj => obj["tmed_avg"])
  const MaxAvgData = props.data.map(obj => obj["tmax_avg"])
  const MaxData = props.data.map(obj => obj["tmax_max"])


  // Dictionary containing the legend names and their status (true or false) where only the value in LegendName is true
  const legendStatus = {
    "Mínimas": false,
    "Medias mínimas": false,
    "Medias": false,
    "Medias máximas": false,
    "Máximas": false,
    "Regression": false
  }
  legendStatus[props.legendName] = true;

  const legendShow = ["Mínimas", "Medias mínimas", "Medias", "Medias máximas", "Máximas"]

  const options = {
      // set background color  to transparent
      backgroundColor: "transparent",

      tooltip: { // Add the hover legend
        trigger: 'axis', // Vertical line selector
        axisPointer: {
          type: 'cross'  // Also adds the horizontal line
        },
        formatter: function (param) {

          if (param.length === 0) {
            var list = "";
          } else {
            var list = [];
            for (var i = 0; i <= param.length - 1; i++) {
              list += markerFormatter({
                color: param[i].color,
                type: param[i].seriesType
              }) + param[i].seriesName + ': ' + param[i].data + " Cº" + '<br/>';
            }
          }
          return [
            param[0].name + '<hr size=1 style="margin: 3px 0">',
            list,
          ].join('');
        },
      },
      xAxis: {
        type: "category",
        data: dateList,
      },
      yAxis: {
        type: "value",
        // name: "Temperatura (ºC)",
        min: function (value) {
          return (value.min - 0.5).toFixed(0);
        },
        axisLabel: {
          formatter: '{value}' + unitsString
        }
      },
      grid: [
        {
          left: props.smDown ? 40 : props.mdDown ? 70 : 70,
          right: props.smDown ? 10 : props.mdDown ? 60 : 60,
          top: 40,
          height: 234,
        }
      ],

      series: [
        // Max and min points:
        {
          type: 'effectScatter',
          name: "Mínimas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            maxPos(MinData)
          ],
          color: theme.palette.scatter.max,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Mínimas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            minPos(MinData)
          ],
          color: theme.palette.scatter.min,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias mínimas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            maxPos(MinAvgData)
          ],
          color: theme.palette.scatter.max,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias mínimas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            minPos(MinAvgData)
          ],
          color: theme.palette.scatter.min,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            maxPos(AvgData)
          ],
          color: theme.palette.scatter.max,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            minPos(AvgData)
          ],
          color: theme.palette.scatter.min,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias máximas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            maxPos(MaxAvgData)
          ],
          color: theme.palette.scatter.max,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Medias máximas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            minPos(MaxAvgData)
          ],
          color: theme.palette.scatter.min,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Máximas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            maxPos(MaxData)
          ],
          color: theme.palette.scatter.max,
          z: 10,
        },
        {
          type: 'effectScatter',
          name: "Máximas",
          symbolSize: 10,
          tooltip: {show: false},
          data: [
            minPos(MaxData)
          ],
          color: theme.palette.scatter.min,
          z: 10,
        },

        // Raw data
        {
          name: "Mínimas",
          data: MinData,
          type: "line",
          smooth: true,
          color: theme.palette.temperatures.min,
          markLine: MarkLineGenerator({
            dateList: dateList,
            data: LinearRegression(dateList, MinData),
            theme: theme,
          })
        },
        {
          name: "Medias mínimas",
          data: MinAvgData,
          type: "line",
          smooth: true,
          color: theme.palette.temperatures.avg_min,
          markLine: MarkLineGenerator({
            dateList: dateList,
            data: LinearRegression(dateList, MinAvgData),
            theme: theme,
          })
        },
        {
          name: "Medias",
          data: AvgData,
          type: "line",
          smooth: true,
          color: theme.palette.plot.primary,
          // Add the arrow and the circle:
          markLine: MarkLineGenerator({
            dateList: dateList,
            data: LinearRegression(dateList, AvgData),
            theme: theme,
          })
        },
        {
          name: "Medias máximas",
          data: MaxAvgData,
          type: "line",
          smooth: true,
          color: theme.palette.temperatures.avg_max,
          markLine: MarkLineGenerator({
            dateList: dateList,
            data: LinearRegression(dateList, MaxAvgData),
            theme: theme,
          })
        },
        {
          name: "Máximas",
          data: MaxData,
          type: "line",
          smooth: true,
          color: theme.palette.temperatures.max,
          markLine: MarkLineGenerator({
            dateList: dateList,
            data: LinearRegression(dateList, MaxData),
            theme: theme,
          })
        },
        // Regressions
        {
          name: "Mínimas",
          data: LinearRegression(dateList, MinData),
          showSymbol: false,
          tooltip: {show: false},
          type: "line",
          smooth: true,
          lineStyle: {
            color: theme.palette.text.primary,
            width: 1,
            type: "dashed",
          }
        },
        {
          name: "Medias mínimas",
          data: LinearRegression(dateList, MinAvgData),
          showSymbol: false,
          tooltip: {show: false},
          type: "line",
          smooth: true,
          lineStyle: {
            color: theme.palette.text.primary,
            width: 1,
            type: "dashed",
          }
        },
        {
          name: "Medias",
          data: LinearRegression(dateList, AvgData),
          showSymbol: false,
          tooltip: {show: false},
          type: "line",
          smooth: true,
          lineStyle: {
            color: theme.palette.text.primary,
            width: 1,
            type: "dashed",
          }
        },
        {
          name: "Medias máximas",
          data: LinearRegression(dateList, MaxAvgData),
          showSymbol: false,
          tooltip: {show: false},
          type: "line",
          smooth: true,
          lineStyle: {
            color: theme.palette.text.primary,
            width: 1,
            type: "dashed",
          }
        },
        {
          name: "Máximas",
          data: LinearRegression(dateList, MaxData),
          showSymbol: false,
          tooltip: {show: false},
          type: "line",
          smooth: true,
          lineStyle: {
            color: theme.palette.text.primary,
            width: 1,
            type: "dashed",
          }
        },
      ],

      legend: {
        selectedMode: 'single',
        data: legendShow,
        type: 'scroll',

        top: 10,
        bottom: 20,
        itemWidth: 15,
        textStyle: {
          fontSize: 12,
          fontWeight: "bold",
          fontFamily: "Roboto"
        },
        selected: legendStatus,
        icon: "none",
        inactiveColor: theme.palette.legend.inactive,
      },

      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'none',
          start: props.start,
          end: props.end,
          preventDefault: false, // Mobile phone fix
        }
      ]
    }
  ;

  function onChartReady(param) {
    // console.log('LinePlot is ready');
  }

  function onChartClick(param, echarts) {
    // console.log(param, echarts);
  };

  function onChartLegendselectchanged(param, echarts) {
    //console.log(param, echarts);
    props.setLegendName(param.name);
  };

  function onDataZoom(param, echarts) {
    props.onChange(param.start, param.end);
    if (!zoomLogged) {
      UserInteractionGA("Plot Interaction", "Zoomed", "Hist. Temp. Plot")();
      zoomLogged = true;
    }
  };

  return (
    <ReactEcharts
      option={options}
      style={{height: "100%", width: "100%"}}
      theme={props.mode}
      onChartReady={onChartReady}
      onEvents={{
        'click': onChartClick,
        'legendselectchanged': onChartLegendselectchanged,
        'dataZoom': onDataZoom,
      }}
    />
  );
}

export default TempPlot;
