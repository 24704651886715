import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import logoHorizontal from "../logo/horizontal.png"
import smallLogo from "./small.png"

const ToolBarLogo = ({alt}) => {
    const theme = useTheme();
    const height = theme.mixins.toolbar.minHeight * 0.7; // 70% of toolbar size
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const logoSrc = isSmDown ? smallLogo : logoHorizontal;

    return (
        <img src={logoSrc} alt={alt} style={{height: height, width: 'auto'}}/>
    );
};

export default ToolBarLogo;
