import React from 'react';
import "./styles/main.css";

import {Route, Routes} from "react-router-dom";

import Home from "./pages/Home";
import {QueryClient, QueryClientProvider} from 'react-query'
import {HelmetProvider} from "react-helmet-async";

const queryClient = new QueryClient()

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Home/>}/>
        </Routes>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
