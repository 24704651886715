/**
 * @fileoverview This file contains the CalculateTempPrecRanking function which calculates the ranking of a specific weather station based on temperature and rainfall changes.
 */

import {generateRows} from "../historic/StationHistoricRankingTable";
import {StableSort} from "./StableSort";
import {GetComparator} from "./GetComparator";

/**
 * Calculate the ranking of a specific weather station based on temperature and rainfall changes.
 * @param {Object} props - The properties object.
 * @param {Array} props.data - The data array containing information about each weather station.
 * @param {string} props.stationName - The name of the station for which the ranking is to be calculated.
 * @returns {Object} An object containing the temperature ranking, the rainfall ranking, and the total number of stations.
 */
export function CalculateTempPrecRanking(props) {
    // Generate an array of rows, where each row represents a weather station with its respective temperature and rainfall changes.
    const rows = generateRows(props.data);

    // Iterate over each row in the rows array.
    for (let i = 0; i < rows.length; i++) {
        // Multiply the temperature_change and raining_change properties by 10000 to convert them to a whole number format.
        rows[i].temperature_change = rows[i].temperature_change * 10000;
        // Multiply by 1000 because do not understand decimals
        rows[i].raining_change = rows[i].raining_change * 10000;
    }

    // Sort the rows array in ascending order by temperature_change.
    const sortedRows_temp = StableSort(rows, GetComparator("asc", "temperature_change"));
    // Create a new array that contains only the station names.
    const sortedNames_temp = sortedRows_temp.map((row) => row.station);
    // Find the index of the station specified in props.stationName in the sortedNames_temp array, subtract it from the length of the array, and assign the result to idx_temp.
    const idx_temp = sortedNames_temp.length - sortedNames_temp.indexOf(props.stationName);

    // Sort the rows array in descending order by raining_change.
    const sortedRows_rain = StableSort(rows, GetComparator("desc", "raining_change"));
    // Create a new array that contains only the station names.
    const sortedNames_rain = sortedRows_rain.map((row) => row.station);
    // Find the index of the station specified in props.stationName in the sortedNames_rain array, subtract it from the length of the array, and assign the result to idx_rain.
    const idx_rain = sortedNames_rain.length - sortedNames_rain.indexOf(props.stationName);

    // Return an object that contains the temperature ranking (idx_temp), the rainfall ranking (idx_rain), and the total number of stations (rankingLength).
    return {
        temp: idx_temp,
        rain: idx_rain,
        rankingLength: sortedNames_temp.length
    }
}