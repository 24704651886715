import allLogo from "../logo/all.png"

const MenuLogo = ({alt}) => {
    const logoSrc = allLogo;

    return (
        <img src={logoSrc} alt={alt}
             style={{width: '80%', height: 'auto'}}
        />
    );
};

export default MenuLogo;
