import config from '../../Config'

export function AggApiPathGenerator(props) {
    const basePath = props.basePath
    const minDate = "min_date=" + props.minDate
    const maxDate = "max_date=" + props.maxDate
    const collection = "collection=" + props.collection
    const fields = "fields=" + props.fields
    const aggTypes = "agg_types=" + props.aggTypes
    const naturalYear = "natural_year=" + props.naturalYear

    var getRequest = config.apiUrl + basePath + "/?" + collection + "&" + fields + "&" + aggTypes + "&" + naturalYear

    // if minDate is not undefined, append it to the request
    if (props.minDate !== undefined) {
        getRequest = getRequest.concat("&" + minDate)
    }

    // if maxDate is not undefined, append it to the request
    if (props.maxDate !== undefined) {
        getRequest = getRequest.concat("&" + maxDate)
    }

    // append minDate and MaxDate only if they are not nul
    return getRequest
}