import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {StableSort} from "../utils/StableSort";
import {GetComparator} from "../utils/GetComparator";
import {styled} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {lightTheme} from "../../styles/lightTheme";
import {darkTheme} from "../../styles/darkTheme";
import {customRgb} from "../utils/hexToRgb";
import {useCallback, useEffect, useState} from "react";
import {UserInteractionGA} from "../ReactGaComponents";


const TableCellStyled = styled(TableCell)(({theme}) => ({
    fontSize: '0.88rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.72rem',
    },
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily
}));


export function createData(isSameYear, year, prec_sum, position) {

    // If is SameYear is false we are in the case of a year range:
    if (!isSameYear) {
        return {
            year: year.toString() + '-' + (year + 1).toString(),
            prec_sum,
            position
        };
    }
    else {
        return {
            year: (year).toString(),
            prec_sum,
            position
        };
    }
}

export function generateRows(isSameYearValue, data) {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
        // * 10 to consider 10 years instead of 1
        rows.push(createData(
            isSameYearValue,
            data[i].year,
            data[i].prec_sum,
            i + 1,
        ));
    }
    return rows;
}

export function formatSelectedYear(isSameYearValue, selectedYear) {
    if (isSameYearValue) {
        return selectedYear.toString()
    }
    else {
        return (selectedYear - 1).toString() + '-' + selectedYear.toString()
    }
}

function getStartingPage(order, orderBy, rows, selected, rowsPerPage) {
    const sortedRows = StableSort(rows, GetComparator(order, orderBy));
    const sortedNames = sortedRows.map((row) => row.year);
    const idx = sortedNames.indexOf(selected);
    const starting_page = Math.floor(idx / rowsPerPage)

    // Return page 0 if the selected year is not in the data
    if (idx === -1) {
        return 0
    }
    else {
        return starting_page
    }
}

const headCells = [
    {
        id: 'position',
        align: "center",
        label: '#',
        shortLabel: '#',
    },
    {
        id: 'year',
        align: "center",
        label: 'Año',
        shortLabel: 'Año',
    },
    {
        id: 'prec_sum',
        align: "center",
        label: 'Precipitación acumulada (L/m²)',
        shortLabel: 'Prec. acum. (L/m²)',
    }
];

const DEFAULT_ORDER = 'desc';
const DEFAULT_ORDER_BY = 'prec_sum';
const DEFAULT_ROWS_PER_PAGE = 10;

function EnhancedTableHead(props) {
    const smDown = useMediaQuery("(max-width:600px)");
    // Delete entry with id "region" from headCells list of dictionaries
    const headCells = props.headCells

    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (newOrderBy) => (event) => {
        onRequestSort(event, newOrderBy);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {/* Position is not sortable */}
                        {headCell.id === 'position' ? headCell.label :
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {!smDown ? headCell.label : headCell.shortLabel}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function PrecipitationRankingTable(props) {

    function defineRows() {
        // remove entries with Null prec_sum in props.data and store it in const dataNoNull
        const dataNoNull = props.data.filter(function (el) {
            return el.prec_sum != null;
        });
        // Sort dataNoNull by prec_sum
        dataNoNull.sort(function (a, b) {
            return b.prec_sum - a.prec_sum;
        });

        // To delete provincia column if screen is small
        const rows = generateRows(props.isSameYear, dataNoNull);

        // Multiply row prec_sum by 1000. Why? Seems that sort function has problems to
        // say that 1100 is larger than 112
        for (let i = 0; i < rows.length; i++) {
            rows[i].prec_sum = rows[i].prec_sum * 10000;
        }
        return(rows)
    }

    function defineVisibleRows() {
        // This function determines which rows are shown on the table
        let rowsOnMount = StableSort(
            rows,
            GetComparator(order, orderBy),
        );

        rowsOnMount = rowsOnMount.slice(
            (0 + page) * rowsPerPage,
            (0 + page) * rowsPerPage + rowsPerPage,
        );

        return(rowsOnMount);
    }

    const [order, setOrder] = useState(DEFAULT_ORDER);
    const orderBy = props.orderBy
    const setOrderBy = props.setOrderBy
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
    const [rows, setRows] = useState(defineRows({data: props.data}));
    const [page, setPage] = useState(getStartingPage(order, orderBy, rows, formatSelectedYear(props.isSameYear, props.selectedYear), rowsPerPage))
    const [visibleRows, setVisibleRows] = useState(defineVisibleRows());
    const [paddingHeight, setPaddingHeight] = useState(0);

    // General useEffect to update rows when props.data changes
    useEffect(() => {
        setRows(defineRows({data: props.data}))
    }, [props.data, props.stationName])

    useEffect(() => {
        setPage(getStartingPage(order, orderBy, rows, formatSelectedYear(props.isSameYear, props.selectedYear), rowsPerPage))
    }, [formatSelectedYear(props.isSameYear, props.selectedYear), rowsPerPage, rows])

    useEffect(() => {
        setVisibleRows(defineVisibleRows());
    }, [formatSelectedYear(props.isSameYear, props.selectedYear), page, order, orderBy, rowsPerPage, props.stationName, rows]);

    const handleRequestSort = useCallback(
        (event, newOrderBy) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = StableSort(rows, GetComparator(toggledOrder, newOrderBy));
            setPage(0); // additional logic to reset page to 0
            const updatedRows = sortedRows.slice(0, rowsPerPage);

            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    useEffect(() => {
    // Initializes the rank before clicking
    const selectedYearPosition = rows.findIndex(row => row.year === formatSelectedYear(props.isSameYear, props.selectedYear)) + 1;

    // Update the yearRanking state variable
    props.setYearRanking(selectedYearPosition);
    }, []);

    const handleClick = (event, year, isSameYearValue) => {
        /*
            Why do we need 2 different states for selectedYear (selectedYear and selectedYearInTable)?
            Because selectedYear is used to retrieve data from the API, while selectedYearInTable is used to
            display the data in the table. This is because we want the SelectedYear to update depending on the
            date range selected in the plot independently of if a new API call is made or not.
         */

        const selectedYearPosition = rows.findIndex(row => row.year === year) + 1;
        // Update the stationRanking state variable
        props.setYearRanking(selectedYearPosition);

        if (isSameYearValue) {
            if (props.selectedYearInTableAux > props.selectedYear) {
                    props.setSelectedYear(parseInt(year) + 1);
                    props.setSelectedYearInTable(parseInt(year));
            }
            else {
                    props.setSelectedYear(parseInt(year));
                    props.setSelectedYearInTable(parseInt(year));
            }
        }
        else {
            // takes the second year in "2019-2020" format as an integer
            year = parseInt(year.split('-')[1])
            props.setSelectedYear(year);
            props.setSelectedYearInTable(year);
        }
    };

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);

            const sortedRows = StableSort(rows, GetComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );

            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = (33) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, rowsPerPage, rows],
    );


    const handleChangeRowsPerPage = useCallback(
        (event) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);

            const sortedRows = StableSort(rows, GetComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );

            setVisibleRows(updatedRows);

            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [order, orderBy, rows],
    );

    const isSelected = (year) => {
        // Check if the selectedYear exists in the data
        const yearExists = rows.some(row => row.year === formatSelectedYear(props.isSameYear, props.selectedYear));

        // If the selectedYear does not exist in the data, return false
        if (!yearExists) {
            return false;
        }

        // If the selectedYear exists in the data, check if it matches the current year
        return formatSelectedYear(props.isSameYear, props.selectedYear).indexOf(year) !== -1;
    };    // Replicate isSelected but for a numeric year

    // generate 2 const with the difference between max and min for precipitation_change and raining_change
    const maxPrec = Math.max.apply(Math, rows.map(function (o) {
        return o.prec_sum;
    }))
    const minPrec = Math.min.apply(Math, rows.map(function (o) {
        return o.prec_sum;
    }))

    const avgPrec = (maxPrec + minPrec) / 2

    function mapValues2ColorsPositive(value) {
        // value is between 0 and 1
        const opacity =   1 / (maxPrec - avgPrec) * (value - avgPrec)
        // We replace minPrec by avgPrec to use 2 sclaes, one for positive and one for
        // negative values
        return opacity
    }

    function mapValues2ColorsNegative(value) {
                // value is between 0 and 1
        const opacity =   1 / (avgPrec - minPrec) * (avgPrec - value)
        // We replace minPrec by avgPrec to use 2 scales, one for positive and one for
        // negative values
        return opacity
    }

    const TableRowStyled = styled(TableRow)({
        // Defines selected row color
        '&.Mui-selected, &.Mui-selected:hover': {
            '& .MuiTableCell-root': {
                backgroundColor: props.mode === 'light' ? lightTheme.palette.primary.light : darkTheme.palette.primary.light,
            },
        },
    })

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2, backgroundColor:  props.mode === 'light' ? lightTheme.palette.background.tables : darkTheme.palette.background.tables}}>
                <TableContainer sx={{height: 300}}>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            // I think we can delete numSelected
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            setPage={setPage}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.year);
                                    const theme = props.mode === 'light' ? lightTheme : darkTheme

                                    return (
                                        <TableRowStyled
                                            className={"historical-prec-changes-table"}
                                            hover
                                            onClick={(event) => {
                                              handleClick(event, row.year, props.isSameYear);
                                              UserInteractionGA("Table Click", 'Prec. Table Click' ,row.year)();
                                            }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.year}
                                            selected={isItemSelected}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <TableCellStyled align="center">{row.position}</TableCellStyled>
                                            <TableCellStyled align="center">{row.year}</TableCellStyled>
                                            <TableCellStyled align="center"
                                                             sx={{
                                                                 backgroundColor: row.prec_sum > avgPrec ?
                                                                     customRgb(theme.palette.primary.main, mapValues2ColorsPositive(row.prec_sum)) :
                                                                     customRgb(theme.palette.secondary.main, mapValues2ColorsNegative(row.prec_sum))
                                                             }}>
                                                {/* Divide by 10000 because we had multiplied before to solve some sort issues */}
                                                {(row.prec_sum / 10000).toFixed(2)}
                                            </TableCellStyled>
                                        </TableRowStyled>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Nº filas:"}
                />
            </Paper>
        </Box>
    );
}
