/*
    Function consuming ArrayToReduce and ArrayRef and returning the same ArrayToReduce array where,
    if ArrayRef starts with n Nulls the first n values of ArrayToReduce are also Nulls
*/

export function AddNulls2Reference(ArrayToReduce, ArrayRef) {
    let ArrayToReduceAux = ArrayToReduce.slice()
    let ArrayRefAux = ArrayRef.slice()
    let i = 0
    while (ArrayRefAux[i] === null) {
        ArrayToReduceAux[i] = null
        i++
    }
    return ArrayToReduceAux
}

/*
    Same but where the Nulls in ArrayRef are at the end of the array and we want ArrayToReduce to have
    the same Nulls at the end
 */

export function AddNulls2ReferenceEnd(ArrayToReduce, ArrayRef) {
    let ArrayToReduceAux = ArrayToReduce.slice()
    let ArrayRefAux = ArrayRef.slice()
    let i = ArrayRefAux.length - 1
    while (ArrayRefAux[i] === null) {
        ArrayToReduceAux[i] = null
        i--
    }
    return ArrayToReduceAux
}