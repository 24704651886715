import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {StoreVariablesProvider} from "./store/StoreVariables";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CssBaseline>
        <StoreVariablesProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </StoreVariablesProvider>
    </CssBaseline>
);
