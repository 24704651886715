export function FormatReferenceData(data, lengh) {
    // Delete data entries after length
    if (data.length > lengh) {
        data.splice(lengh, data.length - lengh);
    }
    // Add data entries before length
    if (data.length < lengh) {
        for (let i = 0; i < lengh - data.length; i++) {
            data.unshift(0);
        }
    }
    return data;
}
