import {Typography} from "@mui/material";

export default function LastUpdate(props) {
    return (
        <Typography
            variant="caption"
            color="textSecondary"
            align="left"
            sx={{
                pl: 1,
            }}
        >
            * Ultima actualización: {props.date} {props.hour}
        </Typography>
    );
}
