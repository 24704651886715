function LinearRegression(values_x, values_y) {
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let count = 0;
    let x = 0;
    let y = 0;
    const values_length = values_x.length;
    // replace missing values in values_y with the average of the values
    const non_null_list = values_y.filter((x) => x !== null);
    const avg = non_null_list.reduce((a, b) => a + b, 0) / non_null_list.length;
    values_y = values_y.map(x => x === null ? avg : x);

    if (values_length !== values_y.length) {
        throw new Error('The parameters values_x and values_y need to have same size!');
    }

    if (values_length === 0) {
        return [[], []];
    }

    for (let v = 0; v < values_length; v++) {
        x = values_x[v];
        y = values_y[v];
        sum_x += x;
        sum_y += y;
        sum_xx += x * x;
        sum_xy += x * y;
        count++;
    }


    let m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    let b = (sum_y / count) - (m * sum_x) / count;
    let result_values_x = [];
    let result_values_y = [];

    for (let v = 0; v < values_length; v++) {
        x = values_x[v];
        y = x * m + b;
        result_values_x.push(x);
        result_values_y.push(y);
    }
    return result_values_y;
}

export default LinearRegression;