import React from "react";

// Pages
import Temperature from "./Temperature";
import Historic from "./Historic";
import Precipitation from "./Precipitation";

// Store
import {useThemeContext} from "../store/StoreVariables";

const Home = () => {
    const {pageSelected} = useThemeContext();
    return (
        <div>
            {
                pageSelected === "Histórico" ? <Historic/> :
                pageSelected === "Precipitación" ? <Precipitation/> : <Temperature/>}
        </div>
    );
};

export default Home;
