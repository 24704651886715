import config from '../../Config'

function ReferenceApiPathGenerator(props) {
    const query = props.query
    const collection = "collection=" + props.collection
    const max_date = "max_date=" + props.maxDate
    const fields = "fields=" + props.fields

    const getRequest = config.apiUrl + query + "/?" + collection + "&" + max_date + "&" + fields

    return getRequest
}

export default ReferenceApiPathGenerator

