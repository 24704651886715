import config from '../Config'

// Data Quality function evaluating if first month and day in referenceQuery.data data is the same as dailyQuery.data
const IsFirstDateEquivalent = (referenceData, dailyData) => {
    const referenceDay = referenceData[0].day
    const referenceMonth = referenceData[0].month
    const dailyDate = new Date(dailyData[0].date)
    if (config.devMode) {
        if (referenceDay !== dailyDate.getDate() || referenceMonth !== dailyDate.getMonth() + 1) {

            console.log("Reference and daily data do not match")
            // Print both dates in YYYY-MM-DD format:
            console.log("Reference date: " + referenceData[0].year + "-" + referenceMonth + "-" + referenceDay)
            console.log("Daily date: " + dailyDate.getFullYear() + "-" + (dailyDate.getMonth() + 1) + "-" + dailyDate.getDate())

        } else {
            console.log("Reference and daily data match")
        }
    }
}

export default IsFirstDateEquivalent
