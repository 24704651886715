import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

export function TempPrecGauge(props) {

    const gaugeData = [
        {
            value: props.tempRanking,
            name: '# Aumento Temp.',
            title: {
                offsetCenter: ['0%', '-40%']
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '-19%']
            },
        },
        {
            value: props.rainRanking,
            name: '# Reduccion Prec.',
            title: {
                offsetCenter: ['0%', '19%']
            },
            detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '40%']
            }
        }
    ];

    const options = {
        backgroundColor: "transparent",

        series: [
            {
                type: 'gauge',
                min: 0,
                max: props.rankingLenght,
                startAngle: 90,
                endAngle: -270,
                pointer: {
                    show: false
                },
                detail: {
                    formatter: '{value}' + "º" + " / " + props.rankingLenght,
                    width: 70
                },
                data: gaugeData,
            }
        ]
    };

    return (
        <ReactEcharts
            option={options}
            theme={props.mode}
        ></ReactEcharts>
    );
}
