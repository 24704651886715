import {createContext, useState, useContext} from "react";
import getCurrentDate from "../components/utils/CurrentDate";

export const StoreVariables = createContext()

export const StoreVariablesProvider = (props) => {
    // Styles
    const [mode, setMode] = useState("light")

    // Selectors
    const [pageSelected, setPageSelected] = useState("Temperatura")
    const [stationName, setStationName] = useState("Madrid - Retiro")
    const [regionName, setRegionName] = useState("Madrid")
    const [stationMenuName, setStationMenuName] = useState("Madrid - Retiro");

    // Plots Zoom
    const [zoomStartDaily, setZoomStartDaily] = useState(75);
    const [zoomEndDaily, setZoomEndDaily] = useState(100);
    const [minDateSelected, setMinDateSelected] = useState(false)
    const [maxDateSelected, setMaxDateSelected] = useState(getCurrentDate())

    // Table Year
    const [selectedYearInTable, setSelectedYearInTable] = useState(getCurrentDate().split("-")[0])
    const [selectedYear, setSelectedYear] = useState(getCurrentDate().split("-")[0])

    // Ranking table
    const [orderTempBy, setOrderTempBy] = useState("tmed_avg")

    const values = {

        // Styles
        mode,
        setMode,

        // Selectors
        pageSelected,
        setPageSelected,
        stationName,
        setStationName,
        regionName,
        setRegionName,
        stationMenuName,
        setStationMenuName,

        // Plots Zoom
        zoomStartDaily,
        setZoomStartDaily,
        zoomEndDaily,
        setZoomEndDaily,
        minDateSelected,
        setMinDateSelected,
        maxDateSelected,
        setMaxDateSelected,

        // Rank table params:
        selectedYearInTable,
        setSelectedYearInTable,
        selectedYear,
        setSelectedYear,
        orderTempBy,
        setOrderTempBy
    }

    return (
        <StoreVariables.Provider value={values}>
            {props.children}
        </StoreVariables.Provider>
    )
}
export const useThemeContext = () => {
    const context = useContext(StoreVariables)
    return context
}