import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import ReactGA from 'react-ga4'; // Google Analytics 4

import LinearRegression from "../../utils/LinearRegression";
import MarkLineGenerator from "../../utils/MarkLineGenerator";

import {lightTheme} from "../../../styles/lightTheme";
import {darkTheme} from "../../../styles/darkTheme";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {markerFormatter} from "../../utils/MarkerFormatter";
import {UserInteractionGA} from "../../ReactGaComponents";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

let zoomLogged = false;

function PrecPlot(props) {
  const theme = props.mode === 'light' ? lightTheme : darkTheme
  const unitsString = props.smDown ? "" : " L/m²";

  // Time Series:
  const dateList = props.data.map(obj => obj.year);
  const prec_sum = props.data.map(obj => obj.prec_sum);

  const prec_sum_regression = LinearRegression(dateList, prec_sum);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    // ...existing click handling code...
  };

  // Function returning the max and its position in an array. The returned format is [position, max]
  function max(array) {
    let max = array[0];
    let position = 0;
    for (let i = 1; i < array.length; i++) {
      if (array[i] > max) {
        max = array[i];
        position = i;
      }
    }
    return [position, max];
  }

  // Function returning the min and its position in an array. The returned format is [position, min]
  function min(array) {
    // Generate an array where Null values in array are replaced by 999 (trick to avoid Nulls
    // considered as Nulls and therefore the min)
    let array_f = array.map(function (item) {
      if (item === null) {
        return 999;
      } else {
        return item;
      }
    });

    let min = array_f[0];
    let position = 0;
    for (let i = 1; i < array_f.length; i++) {
      if (array_f[i] < min) {
        min = array_f[i];
        position = i;
      }
    }
    return [position, min];
  }

  const options = {

    /*
    title: {
        text: "Evolucion precipitaciones (L/m²)",
        left: 'center',
        textStyle: {
            fontSize: 20,
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "Roboto"

        }
    },
    */

    // set background color  to transparent
    backgroundColor: "transparent",

    tooltip: {
      // Add the hover legend
      trigger: "axis", // Vertical line selector
      axisPointer: {
        type: "cross", // Also adds the horizontal line
      },
      formatter: function (param) {

        if (param.length === 0) {
          var list = "";
        } else {
          var list = [];
          for (var i = 0; i <= param.length - 1; i++) {
            list += markerFormatter({
              color: param[i].color,
              type: param[i].seriesType
            }) + param[i].seriesName + ': ' + param[i].data + " L/m²" + '<br/>';
          }
        }
        return [
          param[0].name + '<hr size=1 style="margin: 3px 0">',
          list,
        ].join('');
      },
    },
    xAxis: {
      type: "category",
      data: dateList,
    },
    yAxis: {
      type: "value",
      //name: "Precipitaciones (L/m²)",
      min: function (value) {
        const min = value.min - 50;
        const minDivided = min / 100;
        const valueRounded = minDivided.toFixed(0);
        return valueRounded * 100;
      },
      axisLabel: {
        formatter: '{value}' + unitsString
      },
    },
    grid: [
      {
        left: props.smDown ? 40 : props.mdDown ? 70 : 70,
        right: props.smDown ? 10 : props.mdDown ? 60 : 60,
        top: 40,
        height: 234,
      }
    ],
    series: [
      {
        type: "effectScatter",
        symbolSize: 10,
        tooltip: {show: false},
        data: [max(prec_sum)],
        color: theme.palette.scatter.max,
        z: 10,
      },
      {
        type: "effectScatter",
        tooltip: {show: false},
        symbolSize: 10,
        data: [min(prec_sum)],
        color: theme.palette.scatter.min,
        z: 10,
      },
      {
        name: "Suma de precipitaciones",
        data: prec_sum,
        type: "bar",
        smooth: true,
        color: theme.palette.plot.primary,
        markLine: MarkLineGenerator({
          dateList: dateList,
          data: prec_sum_regression,
          theme: theme,
        })
      },
      {
        name: "Regression",
        data: prec_sum_regression,
        showSymbol: false,
        tooltip: {show: false},
        type: "line",
        smooth: true,
        lineStyle: {
          color: theme.palette.text.primary,
          width: 1,
          type: "dashed",
        }
      },
    ],
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
        start: props.start,
        end: props.end,
        preventDefault: false, // Mobile phone fix
      },
    ],
  };

  function onChartReady(param) {
    // console.log('LinePlot is ready');
  }

  function onChartClick(param, echarts) {
    // console.log(param, echarts);
  }

  function onDataZoom(param, echarts) {
    props.onChange(param.start, param.end);
    if (!zoomLogged) {
      UserInteractionGA("Plot Interaction", "Zoomed", "Hist. Prec. Plot")();
      zoomLogged = true;
    }
  }

  return (
    <div onClick={handleClick}>
      <ReactEcharts
        option={options}
        theme={props.mode}
        style={{height: "360px", width: "100%"}}
        onChartReady={onChartReady}
        onEvents={{
          click: onChartClick,
          dataZoom: onDataZoom,
        }}
      />
    </div>
  );
}

export default PrecPlot;
