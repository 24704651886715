import GeneralStructure from "../components/general/GeneralStructure";
import {useThemeContext} from "../store/StoreVariables";
import {useQuery} from "react-query";

// Config
import config from "../components/Config";

// APIs
import DailyApiPathGenerator from "../components/utils/api_requests/DailyApiPathGenerator";
import ReferenceApiPathGenerator from "../components/utils/api_requests/ReferenceApiPathGenerator";

import {useEffect} from "react";
import {useState} from "react";
import {Grid} from "@mui/material";

// Functions
import {FormatDailyTemperatureData} from "../components/utils/FormatDailyTemperatureData";
import {TurnZoomRelative2Position, TurnZoomRelative2PositionEnd} from "../components/utils/TurnZoomRelative2Position";

// Plots
import TempMainPlot from "../components/plots/Temperature/TempMainPlot";
import ChargingData from "../components/plots/ChargingData";
import * as React from "react";
import {FormatCollectionString} from "../components/utils/FormatCollectionString";
import getCurrentDate from "../components/utils/CurrentDate";
import TempEv from "../components/plots/Temperature/TempEvPlot";
import {PageTitle, PlotTitle} from "../components/general/Titles";
import DailyMetrics from "../components/temperature/DailyMetrics";
import EvolutionMetrics from "../components/temperature/EvolutionMetrics";
import TransparentPaper from "../components/general/TransparentPaper";
import {AggApiPathGenerator} from "../components/utils/api_requests/AggApiPathGenerator";
import TemperatureRankingTable from "../components/temperature/TemperatureRankingTable";
import {setYear} from "../components/utils/SetYear";
import IsFirstDateEquivalent from "../components/utils/DataQuality";
import isSameYear from "../components/utils/IsSameYear";
import {TempGauge} from "../components/plots/Gauge/TempGauge";
import useMediaQuery from "@mui/material/useMediaQuery";
import LastTimeStampPathGenerator from "../components/utils/api_requests/LastTimeStampPathGenerator";
import LastUpdate from "../components/general/LastUpdate";
import {Helmet} from "react-helmet-async";
import {UserFirstInteractionGA} from "../components/ReactGaComponents";

export default function Temperature() {
  const {
    mode, stationName, zoomStartDaily, setZoomStartDaily, zoomEndDaily, setZoomEndDaily,
    minDateSelected, setMinDateSelected, maxDateSelected, setMaxDateSelected,
    selectedYearInTable, setSelectedYearInTable, selectedYear, setSelectedYear,
    orderTempBy, setOrderTempBy, setPageSelected, pageSelected
  } = useThemeContext()

  // UI Size
  const smDown = useMediaQuery("(max-width:600px)");
  const mdDown = useMediaQuery("(max-width:900px)");
  const lgDown = useMediaQuery("(max-width:1200px)");

  // Mouse click state:
  const [mouseUnClick, setMouseUnClick] = useState(true)

  // Dates:
  const [yearRanking, setYearRanking] = React.useState(false);
  const currentDate = getCurrentDate() // Daily and Reference Queries always refer Current Date - 1 year

  // Plots
  const [mainPlot, setMainPlot] = useState([])
  const [secondaryPlot, setSecondaryPlot] = useState([])
  const [legendName, setLegendName] = useState("Medias");

  const actualLegendDict = {
    // Translate MongoDB fields to Spanish legend names
    "Medias mínimas": "tmin",
    "Medias": "tmed",
    "Medias máximas": "tmax",
  }

  const refLegendDict = {
    // Translate MongoDB fields to Spanish legend names
    "Medias mínimas": "avg_min_temp",
    "Medias": "avg_temp",
    "Medias máximas": "avg_max_temp",
  }


  const dailyQuery = useQuery({
    queryKey: ["daily", stationName, selectedYear],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = DailyApiPathGenerator({
        query: "get_data_daily",
        database: "daily",
        collection: FormatCollectionString(stationName),
        maxDate: setYear(currentDate, selectedYear),
        fields: "date,tmax,tmed,tmin"
      })
      if (config.devMode) {
        console.log("GET Daily: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    },
    onError: () => {
      // Reset state values to their default values
      setZoomStartDaily(75);
      setZoomEndDaily(100);
      setMinDateSelected(false);
      setMaxDateSelected(getCurrentDate());
      setSelectedYearInTable(getCurrentDate().split("-")[0]);
      setSelectedYear(getCurrentDate().split("-")[0]);
      setOrderTempBy("tmed_avg");
    }
  });

  const referenceQuery = useQuery({
    queryKey: ["reference", stationName],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = ReferenceApiPathGenerator({
        query: "get_reference_data",
        collection: FormatCollectionString(stationName),
        fields: "max_temp,avg_temp,avg_max_temp,avg_min_temp,min_temp",
        maxDate: currentDate
      })
      if (config.devMode) {
        console.log("GET Reference: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  const customYearlyQuery = useQuery({
    queryKey: ['customYearlyTemp', stationName, minDateSelected, maxDateSelected],
    staleTime: Infinity,
    enabled: !!minDateSelected && !!maxDateSelected && !!mouseUnClick,
    queryFn: () => {
      const getRequest = AggApiPathGenerator({
        basePath: "get_yearly_agg_period",
        collection: FormatCollectionString(stationName),
        naturalYear: false,
        minDate: minDateSelected,
        maxDate: maxDateSelected,
        fields: "tmed",
        aggTypes: "avg"
      })
      if (config.devMode) {
        console.log("GET Yearly Custom Data: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  const lastTimeStamp = useQuery(
    {
      queryKey: ["lastTimeStamp", stationName],
      staleTime: Infinity,
      queryFn: () => {
        const getRequest = LastTimeStampPathGenerator({
            stationName: FormatCollectionString(stationName)
          }
        )
        if (config.devMode) {
          console.log("GET Last Date: " + getRequest)
        }
        return fetch(getRequest).then((response) => response.json())
      }
    }
  );

  useEffect(() => {
    // Initializes MinDateSelected and MaxDateSelected after retrieving dailyQuery.data
    // Max date will be the last date available, while min date will be the date 75% of the data
    if (dailyQuery.isSuccess && !minDateSelected) {
      const minDatePosition = TurnZoomRelative2PositionEnd(75, dailyQuery.data.length)
      setMinDateSelected(dailyQuery.data[minDatePosition - 1].date)
      setMaxDateSelected(dailyQuery.data[dailyQuery.data.length - 1].date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess]) // Only the first render

  // TempMain useEffect
  useEffect(() => {
    if (dailyQuery.isSuccess && referenceQuery.isSuccess) {
      const getData = (zoomStart, zoomEnd) => {
        setZoomStartDaily(zoomStart);
        setZoomEndDaily(zoomEnd);
        const [startPosition, endPosition] = TurnZoomRelative2Position(
          zoomStart,
          zoomEnd,
          dailyQuery.data.length
        )

        setMinDateSelected(dailyQuery.data[startPosition].date)
        setMaxDateSelected(dailyQuery.data[endPosition - 1].date)

        // Define Selected year as year in maxDateSelected
        setSelectedYearInTable(dailyQuery.data[endPosition - 1].date.split("-")[0])
      };

      // DQ checks:
      IsFirstDateEquivalent(referenceQuery.data, dailyQuery.data)

      setMainPlot(TempMainPlot({
        onChange: getData,
        mode: mode,
        data: FormatDailyTemperatureData({data: dailyQuery.data}),
        dates: dailyQuery.data.map(obj => obj.date),
        avg_values: dailyQuery.data.map(obj => obj.tmed),
        referenceData: referenceQuery.data,
        zoomStartDaily: zoomStartDaily,
        zoomEndDaily: zoomEndDaily,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess, referenceQuery.isSuccess, referenceQuery.data, dailyQuery.data,
    mode, stationName, smDown, mdDown])

  // TempEv useEffect
  useEffect(() => {
    if (dailyQuery.isSuccess && referenceQuery.isSuccess) {
      setSecondaryPlot(TempEv({
        mode: mode,
        dates: dailyQuery.data.map(obj => obj["date"]),
        actualData: dailyQuery.data,
        setLegendName: setLegendName,
        legendName: legendName,
        refData: referenceQuery.data,
        zoomStartDaily: zoomStartDaily,
        zoomEndDaily: zoomEndDaily,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyQuery.isSuccess, referenceQuery.isSuccess, referenceQuery.data, dailyQuery.data,
    zoomStartDaily, zoomEndDaily, mode, stationName, legendName, smDown, mdDown])


  return (
    <GeneralStructure
      setPageSelected={setPageSelected}
      pageSelected={pageSelected}
    >
      <Helmet>
        <title>ClimaObserver | Temperatura</title>
        <meta name="description" content="Consulta las temperaturas en España en tiempo real. Records históricos de temperatura y variación climática"/>
        <link rel="canonical" href="https://climaobserver.com"/>
      </Helmet>

      <Grid item xs={12} id="GeneralMetricsGrid">
        <TransparentPaper sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          borderRadius: '0',
          height: 'auto'
        }}>
          <PageTitle>
            Ranking Anual (Periodo seleccionado)
          </PageTitle>
          <Grid container spacing={0} id="TablePlusGauge" sx={{
            alignItems: "center"
          }
          }>

            {/* Table */}
            <Grid item xs={12} md={8} lg={8.5} id="Table" sx={{minHeight: 368}}>
              {dailyQuery.isLoading && <ChargingData/>}
              {dailyQuery.isSuccess && customYearlyQuery.isSuccess && referenceQuery.isSuccess &&
                < TemperatureRankingTable
                  selectedYear={selectedYearInTable}
                  selectedYearInTableAux={selectedYear}
                  isSameYear={isSameYear(minDateSelected, maxDateSelected)}
                  setSelectedYear={setSelectedYear}
                  setSelectedYearInTable={setSelectedYearInTable}
                  data={customYearlyQuery.data}
                  stationName={stationName}
                  mode={mode}
                  orderBy={orderTempBy}
                  setOrderBy={setOrderTempBy}
                  setYearRanking={setYearRanking}
                />}
            </Grid>

            {/* Gauge */}
            {!mdDown && (
              <Grid item xs={12} md={4} lg={3.5} id="Gauge" sx={{minHeight: 300}}>
                {dailyQuery.isLoading && customYearlyQuery.isLoading && referenceQuery.isLoading &&
                  <ChargingData/>}
                {dailyQuery.isSuccess && customYearlyQuery.isSuccess && referenceQuery.isSuccess &&
                  <TempGauge
                    mode={mode}
                    ranking={yearRanking}
                    rankingLenght={customYearlyQuery.data.filter(obj => obj["tmed_avg"] !== null).length}
                  />}
              </Grid>
            )}
          </Grid>
        </TransparentPaper>
        <TransparentPaper
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '0',
            mt: 1,
          }}
        >
          {/* Daily reference comparison */}
          <Grid container spacing={0} sx={{alignItems: "center"}}>

            {/* Chart */}
            <Grid item xs={12} md={9} lg={10}>
              <PlotTitle> Actual vs Histórico </PlotTitle>
              <TransparentPaper sx={{
                height: 360,
                pb: 2,
              }}
                                onClick={UserFirstInteractionGA({action: "Plot Click", label: "Primary Temp Plot"})}
                                // Div Mouse or Touch control
                                onMouseDown={() => setMouseUnClick(false)}
                                onMouseUp={() => setMouseUnClick(true)}
                                onTouchStart={() => setMouseUnClick(false)}
                                onTouchEnd={() => setMouseUnClick(true)}
              >
                {dailyQuery.isLoading && <ChargingData/>}
                {dailyQuery.isError && (
                  <div>{`El periodo seleccionado no esta disponible para esta estación`}</div>)}
                {dailyQuery.isSuccess && referenceQuery.isSuccess && mainPlot}
              </TransparentPaper>
            </Grid>

            {/* Metrics */}
            <Grid item xs={12} md={3} lg={2} sx={{p: 0.05}}>
              <TransparentPaper sx={{
                p: 0,
                boxShadow: 'none',
                borderRadius: '0',
              }}>
                {dailyQuery.isSuccess && referenceQuery.isSuccess &&
                  <DailyMetrics
                    reference={referenceQuery.data}
                    actuals={dailyQuery.data}
                    zoomStart={zoomStartDaily}
                    zoomEnd={zoomEndDaily}
                  />}
              </TransparentPaper>
            </Grid>
          </Grid>

          {/* Daily evolution diff */}
          <Grid container spacing={0} sx={{alignItems: "center", mt: 0,}}>

            {/* Chart */}
            <Grid item xs={12} md={9} lg={10}>
              <TransparentPaper xs={12} md={9} lg={10} sx={{
                height: {
                  xs: 340,
                  sm: 308,
                  md: 308
                },
                pb: 2,
              }}
                onClick={UserFirstInteractionGA({action: "Plot Click", label: "Secondary Temp Plot"})}
              >
                {dailyQuery.isLoading && <ChargingData/>}
                {dailyQuery.isError && (
                  <div>{`El periodo seleccionado no esta diponible para esta estación`}</div>)}
                {dailyQuery.isSuccess && referenceQuery.isSuccess && secondaryPlot}
              </TransparentPaper>
            </Grid>

            {/* LastUpdate - Only small screens */}
            <Grid
              item xs={12} md={0} lg={0}
              display={{xs: 'block', md: 'none', lg: 'none'}}
              sx={{mb: 1.5}}
            >
              {lastTimeStamp.isSuccess &&
                <LastUpdate
                  date={lastTimeStamp.data.date}
                  hour={lastTimeStamp.data.hour}
                  smdown={smDown}
                />}
            </Grid>

            {/* Metrics */}
            <Grid item xs={12} md={3} lg={2} sx={{p: 0.05}}>
              <TransparentPaper xs={12} md={3} lg={2} sx={{
                p: 0, boxShadow: 'none', borderRadius: '0',
              }}>
                {dailyQuery.isSuccess && referenceQuery.isSuccess &&
                  <EvolutionMetrics
                    reference={referenceQuery.data.map(obj => obj[refLegendDict[legendName]])}
                    actuals={dailyQuery.data.map(obj => obj[actualLegendDict[legendName]])}
                    zoomStart={zoomStartDaily}
                    zoomEnd={zoomEndDaily}
                  />}
              </TransparentPaper>
            </Grid>
          </Grid>

          {/* LastUpdate - Only big screens */}
          <Grid
            item xs={0} md={12} lg={12}
            display={{xs: 'none', md: 'block', lg: 'block'}}
          >
            {lastTimeStamp.isSuccess &&
              <LastUpdate
                date={lastTimeStamp.data.date}
                hour={lastTimeStamp.data.hour}
                smdown={smDown}
              />}
          </Grid>
        </TransparentPaper>
      </Grid>
    </GeneralStructure>);
}
