import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

// Styles
import {lightTheme} from "../../../styles/lightTheme";
import {darkTheme} from "../../../styles/darkTheme";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {maxPos, maxPosArray, minPos, minPosArray} from "../../utils/ArrayPositionFunctions";
import {markerFormatter} from "../../utils/MarkerFormatter";
import {UserInteractionGA} from "../../ReactGaComponents";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

let zoomLogged = false;

function TempMainPlot(props) {
  const theme = props.mode === 'light' ? lightTheme : darkTheme

  const unitsString = props.smDown ? "" : " °C";

  // extract max and min values from props.data has the form [min, max, min, max]
  const minActualValues = props.data.map(obj => obj[0]);
  const maxActualvalues = props.data.map(obj => obj[1]);

  function renderAverageItem(param, api) {
    var bandWidth = api.size([0, 0])[0] * 0.39;
    var point = api.coord([api.value(0), api.value(1)]);
    return {
      type: 'line',
      transition: ['shape'],
      shape: {
        x1: point[0] - bandWidth,
        x2: point[0] + bandWidth,
        y1: point[1],
        y2: point[1]
      },
      style: {
        fill: api.visual('color'),
        stroke: api.visual('color'),
        lineWidth: 1.5,
      }
    };
  }

  const minHistoric = minPosArray(minActualValues, props.referenceData.map(obj => obj.min_temp))
  const maxHistoric = maxPosArray(maxActualvalues, props.referenceData.map(obj => obj.max_temp))
  const allHistoric = minHistoric.concat(maxHistoric)

  const options = {
    // set background color  to transparent
    backgroundColor: "transparent",

    tooltip: { // Add the hover legend
      trigger: 'axis', // Vertical line selector
      // show: !props.smDown,
      axisPointer: {
        type: 'cross'  // Also adds the horizontal lin
      },
      formatter: function (param) {
        // Historic reference section:
        const lineList = param.filter(obj => obj.componentSubType === "line");
        var historicString = "";
        if (lineList.length !== 0 && lineList[0].data !== null) {
          historicString = ['<b> Temperaturas históricas </b> <br/>'];
          for (var i = 0; i <= lineList.length - 1; i++) {
            historicString += markerFormatter(
              {color: lineList[i].color, type: lineList[i].seriesType}
            ) + lineList[i].seriesName + ': ' + lineList[i].data + "°C <br/>";

          }
        }

        // Record historic section:
        const scatterList = param.filter(obj => obj.componentSubType === "effectScatter");

        var recordString = "";
        if (scatterList.length !== 0 && scatterList[0].data[1] !== null) {
          recordString = ['<b> Otros </b> <br/>'];
          for (var i = 0; i <= scatterList.length - 1; i++) {
            if (scatterList[i].data[1] !== null) {
              if (scatterList[i].seriesName === "Record histórico") {
                recordString += markerFormatter({
                  color: scatterList[i].color,
                  type: 'custom'
                }) + scatterList[i].seriesName + ': ' + scatterList[i].data[1] + "°C <br/>";
              } else {
                recordString += scatterList[i].marker + scatterList[i].seriesName + ': ' + scatterList[i].data[1] + "°C" + '<br/>';
              }
            }
          }
        }

        // Daily section:
        var dailyString = "";
        if (param[0].data[2] !== null && param[1].data !== null && param[0].data[1] !== null) {
          dailyString = [
            '<b> Temperaturas diarias </b> <br/>',
            'Máxima: ' + param[0].data[2].toFixed(2) + "°C <br/>",
            'Media: ' + param[1].data.toFixed(2) + "°C <br/>",
            'Mínima: ' + param[0].data[1].toFixed(2) + "°C",
          ].join('');
        }

        return [
          param[0].name + '<hr size=1 style="margin: 3px 0">',
          dailyString,
          dailyString.length > 0 && historicString.length > 0 ? '<hr size=1 style="margin: 3px 0">' : '',
          historicString,
          historicString.length > 0 && recordString.length > 0 ? '<hr size=1 style="margin: 3px 0">' : '',
          recordString
        ].join('');
      }
    },
    xAxis: {
      type: "category",
      data: props.dates,
      // Note: To make evolution and reference period to match on the dates displayed,
      // we would need to include a Zoom on evolution and hide it.
    },
    yAxis: {
      type: "value",
      min: function (value) {
        const min = value.min - 5;
        const minDivided = min / 5;
        const valueRounded = minDivided.toFixed(0);
        return valueRounded * 5;
      },
      max: function (value) {
        const max = value.max + 5;
        const maxDivided = max / 5;
        const valueRounded = maxDivided.toFixed(0);
        return valueRounded * 5;
      },
      interval: 5,
      axisLabel: {
        formatter: '{value}' + unitsString
      }
    },
    grid: [
      {
        left: props.smDown ? 25 : props.mdDown ? 60 : 60,
        right: props.smDown ? 10 : props.mdDown ? 60 : 60,
        top: 40,
        height: 234,
      }
    ],
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        start: props.zoomStartDaily,
        end: props.zoomEndDaily,
        preventDefault: false, // Mobile phone fix
      }
    ],
    legend: {
      data: [
        {
          name: 'Mín. Histórica',
          icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
        },
        {
          name: 'Media mínima',
          icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
        },
        {
          name: 'Media',
          icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
        },
        {
          name: 'Media máxima',
          icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
        },
        {
          name: 'Máx. Histórica',
          icon: 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z'
        },
        {
          name: 'Record histórico',
          icon: 'diamond'
        }
      ],
      // Add general legend title

      type: 'scroll',
      top: 10,
      bottom: 20,
      textStyle: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "Roboto"
      },
      // Change legend size
      itemWidth: 15,
      selected: {
        'Mín. Histórica': false,
        'Media mínimas': true,
        'Media': true,
        'Media máxima': true,
        'Máx. Histórica': false,
      },
    },
    series: [
      {
        type: 'candlestick',
        barWidth: "80%",
        data: props.data
      },
      {
        type: 'custom',
        name: 'Temperatura media',
        color: theme.palette.plots.candle_avg,
        renderItem: renderAverageItem,
        encode: {
          x: 0,
          y: 1
        },
        z: 10,
        data: props.avg_values
      },
      {
        type: 'effectScatter',
        name: 'Record histórico',
        symbolSize: 7,
        symbol: "diamond",
        data: allHistoric,
        color: theme.palette.scatter.max_hist,
        z: 20,
      },
      {
        type: 'effectScatter',
        name: 'Máxima (Anual)',
        symbolSize: 10,
        data: [
          maxPos(maxActualvalues)
        ],
        color: theme.palette.scatter.max,
        z: 10,
      },
      {
        type: 'effectScatter',
        name: 'Mínima (Anual)',
        symbolSize: 10,
        data: [
          minPos(minActualValues)
        ],
        color: theme.palette.scatter.min,
        z: 10,
      },
      {
        type: 'line',
        name: 'Máx. Histórica',
        showSymbol: false,
        smooth: true,
        color: theme.palette.temperatures.max,
        data: props.referenceData.map(obj => obj.max_temp),
      },
      {
        type: 'line',
        name: 'Media máxima',
        showSymbol: false,
        smooth: true,
        color: theme.palette.temperatures.avg_max,
        data: props.referenceData.map(obj => obj.avg_max_temp),
      },
      {
        type: 'line',
        name: 'Media',
        showSymbol: false,
        smooth: true,
        color: theme.palette.temperatures.avg,
        data: props.referenceData.map(obj => obj.avg_temp),
      },
      {
        type: 'line',
        name: 'Media mínima',
        showSymbol: false,
        smooth: true,
        color: theme.palette.temperatures.avg_min,
        data: props.referenceData.map(obj => obj.avg_min_temp),
      },
      {
        type: 'line',
        name: 'Mín. Histórica',
        showSymbol: false,
        smooth: true,
        color: theme.palette.temperatures.min,
        data: props.referenceData.map(obj => obj.min_temp),
      },
    ]
  };

  function onDataZoom(param) {
    props.onChange(param.start, param.end);
    if (!zoomLogged) {
      UserInteractionGA("Plot Interaction", "Zoomed", "Temp. Plot")();
      zoomLogged = true;
    }
  }

  return (
    <ReactEcharts
      option={options}
      style={{height: "100%", width: '100%'}}
      theme={props.mode}
      onEvents={{
        'dataZoom': onDataZoom
      }}
    />
  );
}

export default TempMainPlot;
