import * as React from 'react';
import LinearRegressionJust2Values from "../utils/LinearRegressionJust2Values";
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography} from "@mui/material";
import {max_range, min_range, avg_range} from "../utils/ArrayFunctions";
import {styled} from "@mui/system";
import {TurnZoomRelative2Position} from "../utils/TurnZoomRelative2Position";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


export default function PeriodMetrics(props) {

    const mdDown = useMediaQuery("(min-width:900px)");

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStart,
        props.zoomEnd,
        props.data.length);

    // const unitsString = props.weatherType === "temperature" ? " °C": " L/m²";

    const regression_values = LinearRegressionJust2Values(props.years, props.data);
    const histDiff = regression_values[1] - regression_values[0]
    const regChange = ((histDiff / (props.years.length - 1)) * (props.zoomEnd - props.zoomStart)).toFixed(2)

    const StyledButton = styled(Button)(({theme}) => ({
        fontSize: '0.88rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.82rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.78rem',
        }
    }));

    const StyledButton2 = (props) => (
        <StyledButton
            sx={{
                pointerEvents: 'none!important',
                color: (theme) => theme.palette.text.primary,
                border: 2,
                borderColor: (theme) => theme.palette.primary.main,
                '&:hover': {
                    border: 2,
                    borderColor: (theme) => theme.palette.primary.main,
                    background: 'none',
                },
                mb: '-1px',
            }}>
            {props.children}
        </StyledButton>
    );

    function StyledButton3(props) {
        return (
            <StyledButton2 key={props.keyValue}>
                {props.text}
                {props.value > 0 ? <ArrowUpwardIcon sx={{
                    fontSize: '0.92rem',
                    color: (theme) => theme.palette.secondary.main,
                    stroke: (theme) => theme.palette.secondary.main,
                    strokeWidth: 1,
                }}/> : <ArrowDownwardIcon sx={{
                    fontSize: '0.92rem',
                    color: (theme) => theme.palette.primary.main,
                    stroke: (theme) => theme.palette.primary.main,
                    strokeWidth: 1,
                }}/>} {props.value}
            </StyledButton2>
        )
    }

    const buttons = [
        <StyledButton2 key={"min"}>
            Mínima: {min_range(props.data, startPosition, endPosition)}
        </StyledButton2>,
        <StyledButton2 key={"max"}>
            Máxima: {max_range(props.data, startPosition, endPosition)}
        </StyledButton2>,
        <StyledButton2 key={"mean"}>
            Media: {avg_range(props.data, startPosition, endPosition)}
        </StyledButton2>,
        <StyledButton3
            key="avgChange"
            keyValue={"change"}
            text={"Cambio: "}
            value={regChange}
        >
        </StyledButton3>,
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                '& > *': {
                    m: 1,
                },
                justifyContent: "center",
            }}
            id="PeriodMetricsBox"
        >
            <ButtonGroup
                color="primary"
                orientation={mdDown ? `vertical` : `horizontal`}
            >
                <Typography sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: "bold",
                    variant: "h5",
                    textAlign: "center",
                    pb: 2,
                    display: {md: 'block', xs: 'none'}
                }}> Periodo seleccionado
                </Typography>
                {buttons}
            </ButtonGroup>

        </Box>
    )
        ;
}

