import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {darken, lighten, styled} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SearchRegion(props) {
    const smDown = useMediaQuery("(max-width:600px)");

    const GroupHeader = styled('div')(({theme}) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        fontSize: smDown ? 14 : 16,
        backgroundColor:
            theme.palette.mode === 'light'
                ? lighten(theme.palette.primary.light, 0.85)
                : darken(theme.palette.primary.main, 0.8),
    }));

    const GroupItems = styled('ul')({
        padding: 0,
        // backgroundColor: "white", In case I want to change the general background
        fontSize: smDown ? 14 : 16,
    });

    const options = props.darray.map((option) => {
        const firstLetter = option[0].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            label: option,
        };
    });

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value} // Avoid warning message
            id="search-regions"
            disableClearable
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            value={{firstLetter: 'M', label: props.regionName}}
            defaultValue={{firstLetter: 'M', label: 'Madrid'}}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.label}
            onChange={
                (event, newValue) => {
                        props.setRegionName(newValue.label)
                        props.setStationMenuName("")
                    ;
                }
            }
            sx={{
                ...props.sx
            }}
            renderInput={(params) =>
                <TextField
                        {...params}
                        // If mdDown, the font size is 16, otherwise 20
                        InputProps={{ ...params.InputProps,  style: { fontSize: smDown ? 14 : 16} }}
                        label="Escoge una provincia"
                />
            }
            renderGroup={(params) => (
            <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
            </li>)}
                />
    );
}

