import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SearchStation(props) {
    const smDown = useMediaQuery("(max-width:600px)");

    const options = props.darray.map((option) => {
        const firstLetter = option[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            // no spreading ... here
            label: option,
        };
    });

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value} // Avoid warning message
            id="seach-stations"
            disableClearable
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            ListboxProps={{
                style: {
                    fontSize: smDown ? 14 : 16
                }
            }}
            value={{firstLetter: 'M', label: props.stationMenuName}}
            getOptionLabel={(option) => option.label}
            onChange={
                (event, newValue) => {
                    if (newValue) {
                        props.setStationName(newValue.label);
                        props.setStationMenuName(newValue.label);
                    }
                    ;
                }
            }
            sx={{
                ...props.sx,
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Escoge una localidad"
                    InputProps={{ ...params.InputProps,  style: { fontSize: smDown ? 14 : 16} }}
                />}
        />
    );
}

