function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16).toString();
    const g = parseInt(result[2], 16).toString();
    const b = parseInt(result[3], 16).toString();

    const rgb = "rgba(" + r + "," + g + "," + b + ","

    return rgb
}

function customRgb(hex, opacity) {
    const rgb = hexToRgb(hex)
    const customRgb = rgb + opacity + ")"
    return customRgb
}

export { customRgb, hexToRgb }