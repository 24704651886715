// return the current date in string format
export default function getCurrentDate(complete = true) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    if (complete) {
        return `${year}-${month}-${day}`;
    } else {
        return `${year}-${month}`;
    }
};

// take a date string and return it one year before
export function getOneYearBefore(date) {
    // Subtract one year and add one day
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() - 1);
    newDate.setDate(newDate.getDate() + 1);

    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth() + 1;
    const newDay = newDate.getDate();

    return `${newYear}-${newMonth}-${newDay}`;
};