import config from '../../Config'

function LastTimeStampPathGenerator(props) {

    const stationName = "name=" + props.stationName
    const getRequest = config.apiUrl + "last_timestamp" + "/?" + stationName

    return getRequest
}

export default LastTimeStampPathGenerator