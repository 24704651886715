// src/components/general/CookieConsent.js
import React, {useState, useEffect} from 'react';
import {Box, Button, Stack} from '@mui/material';
import ReactGA from 'react-ga4'; // Google Analytics 4

const CookieConsent = (props) => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setShowBanner(false);
      initializeGA4();
    } else {
      setShowBanner(true);
      initializeLimitedGA4(); // Initialize limited GA4 for visit counting
    }
  }, []);

  const initializeGA4 = () => {
    ReactGA.initialize('G-RM4R4WXYYQ'); // Full GA4 initialization
  };

  const initializeLimitedGA4 = () => {
    ReactGA.initialize('G-RM4R4WXYYQ', { // Limited GA4 initialization
      gaOptions: {
        storage: 'none',
        clientId: 'anonymized',
        storeGac: false
      }
    });
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
    initializeGA4();
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Stack direction={props.smDown ? 'column' : 'row'}
           sx={{
             position: 'fixed',
             bottom: 0,
             marginLeft: props.smDown ? '0px' : (props.open ? '280px' : '72px'),
             width: props.smDown ? '100%' : (props.open ? 'calc(100% - 280px)' : 'calc(100% - 72px)'),
             backgroundColor: 'rgba(0, 0, 0, 0.8)',
             color: 'white',
             p: 2,
             display: 'flex',
             justifyContent: 'space-between',
             alignItems: 'center',
             zIndex: 1000,
           }}
    >
      <Stack direction={'column'} spacing={2}>
        <Box variant="body1"
             sx={{fontFamily: 'Roboto, sans-serif', fontSize: props.smDown ? '0.75rem' : '0.9rem', ml: 2}}>
          Esta página web solo utiliza cookies para analizar de forma anónima y estadística el uso que haces de la web
          con el objetivo de
          mejorar los contenidos y tu experiencia de navegación.
        </Box>
        <Box mb={2} sx={{
          '& p': {
            margin: 0,
            fontFamily: 'Roboto, sans-serif',
            fontSize: props.smDown ? '0.75rem' : '0.9rem',
            ml: 2
          }
        }}>
          <p> Por favor, acepte o rechace el uso de cookies para este análisis: </p>
        </Box>
      </Stack>

      <Box sx={{ml: 2, mt: 2}}>
        {props.smDown ? (
          <>
            <Button onClick={handleDecline} color="secondary" variant="contained" sx={{mr: 2, mb: 1, minWidth: 100}}>
              Rechazar
            </Button>
            <Button onClick={handleAccept} color="primary" variant="contained" sx={{mr: 2, mb: 1, minWidth: 100}}>
              Aceptar
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleAccept} color="primary" variant="contained" sx={{mr: 2, mb: 1, minWidth: 100}}>
              Aceptar
            </Button>
            <Button onClick={handleDecline} color="secondary" variant="contained" sx={{minWidth: 100}}>
              Rechazar
            </Button>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default CookieConsent;
