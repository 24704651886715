// React
import * as React from 'react';
import {useEffect, useState} from "react";
import {useQuery} from "react-query";

// Config
import config from '../components/Config'

// Mui
import {Grid} from '@mui/material';

// Components
import PeriodMetrics from '../components/historic/PeriodMetrics';
import TempPlot from "../components/plots/Historic/TempPlot";
import PrecPlot from "../components/plots/Historic/PrecPlot";
import ChargingData from "../components/plots/ChargingData";
import StationHistoricRankingTable from "../components/historic/StationHistoricRankingTable";
import {TempPrecGauge} from "../components/plots/Gauge/TempPrecGauge";
import {CalculateTempPrecRanking} from "../components/utils/CalculateTempPrecRanking";
import {PlotTitle, PageTitle} from "../components/general/Titles";
import GeneralStructure from "../components/general/GeneralStructure";
import TransparentPaper from "../components/general/TransparentPaper";

// Utils
import {AggApiPathGenerator} from "../components/utils/api_requests/AggApiPathGenerator";

// Context
import {useThemeContext} from "../store/StoreVariables";
import {FormatCollectionString} from "../components/utils/FormatCollectionString";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Helmet} from "react-helmet-async";
import {UserFirstInteractionGA, UserInteractionGA} from "../components/ReactGaComponents";

export default function Historic() {
  // Context
  const {
    mode,
    stationName,
    setStationName,
    setRegionName,
    setStationMenuName,
    setPageSelected,
    pageSelected
  } = useThemeContext()

  // UI Size
  const smDown = useMediaQuery("(max-width:600px)");
  const mdDown = useMediaQuery("(max-width:900px)");

  // Station ranking
  const [stationRanking, setStationRanking] = React.useState({temp: -1, rain: -1, rankingLength: -1});

  // LinePlot
  const [tempLinePlot, setTempLinePlot] = useState([]);
  const [legendName, setLegendName] = useState("Medias");
  /// Zoom
  const [zoomStartTemp, setZoomStartTemp] = useState(0);
  const [zoomEndTemp, setZoomEndTemp] = useState(100);

  // BarPlot
  const [precPlot, setPrecPlot] = useState([]);
  /// Zoom
  const [zoomStartRain, setZoomStartRain] = useState(0);
  const [zoomEndRain, setZoomEndRain] = useState(100);

  const legend_dict = {
    // Translate MongoDB fields to Spanish legend names
    "Medias": "tmed_avg",
    "Medias máximas": "tmax_avg",
    "Medias mínimas": "tmin_avg",
    "Máximas": "tmax_max",
    "Mínimas": "tmin_min",
  }

  const getTempData = (zoomStart, zoomEnd) => {
    setZoomStartTemp(zoomStart);
    setZoomEndTemp(zoomEnd);
  };

  const getRainData = (zoomStart, zoomEnd) => {
    setZoomStartRain(zoomStart);
    setZoomEndRain(zoomEnd);
  };

  const yearlyQuery = useQuery({
    queryKey: ['yearly', stationName],
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = AggApiPathGenerator({
        basePath: "get_yearly_agg",
        collection: FormatCollectionString(stationName),
        naturalYear: true,
        fields: "prec,tmax,tmax,tmed,tmin,tmin",
        aggTypes: "sum,avg,max,avg,avg,min",
      })
      if (config.devMode) {
        console.log("GET Yearly Data: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  const summaryTableQuery = useQuery({
    queryKey: 'summaryTable',
    staleTime: Infinity,
    queryFn: () => {
      const getRequest = config.apiUrl + "get_summary_table/"
      if (config.devMode) {
        console.log("GET Yearly summary Data: " + getRequest)
      }
      return fetch(getRequest).then((response) => response.json())
    }
  });

  useEffect(() => {
    if (yearlyQuery.isSuccess && yearlyQuery.data.length > 0) {

      setTempLinePlot(TempPlot({
        onChange: getTempData,
        data: yearlyQuery.data,
        setLegendName: setLegendName,
        legendName: legendName,
        mode: mode,
        start: zoomStartTemp,
        end: zoomEndTemp,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
  }, [yearlyQuery.isSuccess, yearlyQuery.data, mode, stationName, smDown, mdDown])


  useEffect(() => {
    if (yearlyQuery.isSuccess && yearlyQuery.data.length > 0) {

      setPrecPlot(PrecPlot({
        onChange: getRainData,
        data: yearlyQuery.data,
        mode: mode,
        start: zoomStartRain,
        end: zoomEndRain,
        smDown: smDown,
        mdDown: mdDown
      }))
    }
  }, [yearlyQuery.isSuccess, mode, stationName, yearlyQuery.data, smDown, mdDown])

  useEffect(() => {
    if (summaryTableQuery.isSuccess) {

      const station_ranking = CalculateTempPrecRanking({
        data: summaryTableQuery.data,
        stationName: stationName,
      })
      setStationRanking(station_ranking)
    }
  }, [summaryTableQuery.isSuccess, stationName, summaryTableQuery.data])


  return (

    <GeneralStructure
      setPageSelected={setPageSelected}
      pageSelected={pageSelected}
    >
      <Helmet>
        <title>ClimaObserver | Histórico</title>
        <meta name="description"
              content="Cambio climático hístorico por localidades. Consulte cuanto ha evolucionado el clima"/>
        <link rel="canonical" href="https://climaobserver.com"/>
      </Helmet>

      <Grid item xs={12} id="GeneralMetricsGrid">
        <TransparentPaper sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          borderRadius: '0',
        }}>
          <PageTitle>
            Variación observada (Promedio 10 años)
          </PageTitle>
          <Grid container spacing={0} sx={{alignItems: "center"}} id="TablePlusGauge">

            {/* Table */}
            <Grid item xs={12} md={8} lg={8.5} id="Table">
              {summaryTableQuery.isSuccess &&
                < StationHistoricRankingTable
                  stationName={stationName}
                  setStationName={setStationName}
                  data={summaryTableQuery.data}
                  setStationMenuName={setStationMenuName}
                  setRegionName={setRegionName}
                  mode={mode}
                />}
            </Grid>

            {/* Gauge */}
            {!mdDown && (
              <Grid item xs={12} md={4} lg={3.5} id="Gauge">
                {summaryTableQuery.isLoading && <ChargingData/>}
                {summaryTableQuery.isError && (
                  <div>{`Ha habido un problema a la hora de cargar los datos: ${yearlyQuery.error}`}</div>)}
                {(summaryTableQuery.isSuccess && stationRanking["temp"] !== -1) &&
                  <TempPrecGauge
                    mode={mode}
                    tempRanking={stationRanking["temp"]}
                    rainRanking={stationRanking["rain"]}
                    rankingLenght={stationRanking["rankingLength"]}/>}
              </Grid>
            )}
          </Grid>
        </TransparentPaper>
        <TransparentPaper
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            borderRadius: '0',
            mt: 1,
          }}
        >
          {/* Temperatures */}
          <Grid container spacing={0} sx={{alignItems: "center"}}>

            {/* Chart */}
            <Grid item xs={12} md={9} lg={10}>
              <PlotTitle> Evolución - Temperaturas </PlotTitle>
              <TransparentPaper xs={12} md={9} lg={10} sx={{
                height: 360,
                pb: 2,
              }}
                                onClick={UserFirstInteractionGA({
                                  action: "Plot Click",
                                  label: "Historic Temp Plot"
                                })}
              >
                {yearlyQuery.isLoading && <ChargingData/>}
                {yearlyQuery.isError && (
                  <div>{`Ha habido un problema a la hora de cargar los datos: ${yearlyQuery.error}`}</div>)}
                {yearlyQuery.isSuccess && tempLinePlot}
              </TransparentPaper>
            </Grid>

            {/* Metrics */}
            <Grid item xs={12} md={3} lg={2} sx={{p: 0.05}}>
              <TransparentPaper xs={12} md={3} lg={2} sx={{
                p: 0,
                boxShadow: 'none',
                borderRadius: '0',
              }}>
                {yearlyQuery.isSuccess && <PeriodMetrics
                  name={legendName}
                  data={yearlyQuery.data.map(obj => obj[legend_dict[legendName]])}
                  years={yearlyQuery.data.map(obj => obj.year)}
                  zoomStart={zoomStartTemp}
                  zoomEnd={zoomEndTemp}
                  weatherType={"temperature"}
                />}
              </TransparentPaper>
            </Grid>
          </Grid>
          {/* Prec */}
          <Grid container spacing={0} sx={{alignItems: "center", mt: 0,}}>
            {/* Chart */}
            <Grid item xs={12} md={9} lg={10} id="PrecChartGrid">
              <PlotTitle> Evolución - Precipitaciones </PlotTitle>
              <TransparentPaper xs={12} md={9} lg={10} sx={{height: 360, pb: 2,}}
                                id="PrecMetricsTransparentPaper"
                                onClick={UserFirstInteractionGA({action: "Plot Click", label: "Historic Prec Plot"})}
              >
                {yearlyQuery.isLoading && <ChargingData/>}
                {yearlyQuery.isError && (
                  <div>{`Ha habido un problema a la hora de cargar los datos: ${yearlyQuery.error}`}</div>)}
                {yearlyQuery.isSuccess && precPlot}
              </TransparentPaper>
            </Grid>
            {/* Metrics */}
            <Grid item xs={12} md={3} lg={2} sx={{p: 0.05}} id="PrecMetricsGrid">
              <TransparentPaper xs={12} md={3} lg={2} sx={{
                p: 0,
                boxShadow: 'none',
                borderRadius: '0',
              }} id="PrecMetricsTransparentPaper">
                {yearlyQuery.isSuccess && <PeriodMetrics
                  name={legendName}
                  data={yearlyQuery.data.map(obj => obj.prec_sum)}
                  years={yearlyQuery.data.map(obj => obj.year)}
                  zoomStart={zoomStartRain}
                  zoomEnd={zoomEndRain}
                  weatherType={"precipitation"}
                />}
              </TransparentPaper>
            </Grid>
          </Grid>
        </TransparentPaper>
      </Grid>
    </GeneralStructure>
  );
}
