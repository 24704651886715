// data dataRegressionDict[legend_dict[props.legendName]]


export default function MarkLineGenerator(props) {
    const point1 = [String(props.dateList.slice(-2)[0]), props.data.slice(-2)[0]]
    const point2 = [String(props.dateList.slice(-2)[1]), props.data.slice(-2)[1]]

    const point3 = [String(props.dateList[0]), props.data[0]]
    const point4 = [String(props.dateList[1]), props.data[1]]

    return (
        {
            lineStyle: {
                color: props.theme.palette.text.primary,
                width: 1,
                type: "dashed",
            },
            data: [
                [
                    {
                        coord: point1,
                        symbol: 'none'
                    },
                    {
                        coord: point2,
                    },
                ],
                [
                    {
                        coord: point3,
                    },
                    {
                        coord: point4,
                        symbol: 'none'
                    },
                ],
            ],
        }
    )
}