import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {StableSort} from "../utils/StableSort";
import {GetComparator} from "../utils/GetComparator";
import {styled} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {removeAddColumn} from "../utils/RemoveAddColumn";
import {lightTheme} from "../../styles/lightTheme";
import {darkTheme} from "../../styles/darkTheme";
import {customRgb} from "../utils/hexToRgb";
import {UserInteractionGA} from "../ReactGaComponents";


const TableCellStyled = styled(TableCell)(({theme}) => ({
    fontSize: '0.88rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.72rem',
    },
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily
}));


export function createData(station, region, temperature_change, raining_change) {
    return {
        station,
        region,
        temperature_change,
        raining_change
    };
}

export function generateRows(data) {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
        // * 10 to consider 10 years instead of 1
        rows.push(createData(
            data[i].name, data[i].district,
            (data[i].tmed_slope * 10).toFixed(3),
            (data[i].prec_slope * 10).toFixed(2)
        ));
    }
    return rows;
}

function getStartingPage(order, orderBy, rows, selected, rowsPerPage) {
    const sortedRows = StableSort(rows, GetComparator(order, orderBy));
    const sortedNames = sortedRows.map((row) => row.station);
    const idx = sortedNames.indexOf(selected);
    const starting_page = Math.floor(idx / rowsPerPage)

    return starting_page
}

const headCells = [
    {
        id: 'position',
        align: "center",
        label: '#',
        shortLabel: '#',
    },
    {
        id: 'station',
        align: "center",
        label: 'Localidad',
        shortLabel: 'Localidad',
    },
    {
        id: 'region',
        align: "center",
        label: 'Provincia',
        shortLabel: 'Prov.',
    },
    {
        id: 'temperature_change',
        align: "center",
        label: 'Temperatura (°C)',
        shortLabel: 'Temp. (°C)',
    },
    {
        id: 'raining_change',
        align: "center",
        label: 'Precipitaciones (L/m²)',
        shortLabel: 'Prec. (L/m²)',
    }
];

const DEFAULT_ORDER = 'desc';
const DEFAULT_ORDER_BY = 'temperature_change';
const DEFAULT_ROWS_PER_PAGE = 10;

function EnhancedTableHead(props) {
    const smDown = useMediaQuery("(max-width:600px)");
    // Delete entry with id "region" from headCells list of dictionaries
    const headCells = removeAddColumn({smDown: smDown, headCells: props.headCells})

    const {order, orderBy, onRequestSort} =
        props;
    const createSortHandler = (newOrderBy) => (event) => {
        onRequestSort(event, newOrderBy);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {/* Position is not sortable */}
                        {headCell.id === 'position' ? headCell.label :
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {!smDown ? headCell.label : headCell.shortLabel}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function StationHistoricRankingTable(props) {
    // To delete provincia column if screen is small
    const smDown = useMediaQuery("(max-width:600px)");
    const rows = generateRows(props.data);

    /*
        Multiply row tmed_slope and prec_slope by 1000. Why? Seems that sort function has problems to
        say that 1100 is larger than 112.
    */
    for (let i = 0; i < rows.length; i++) {
        rows[i].temperature_change = rows[i].temperature_change * 10000;
        rows[i].raining_change = rows[i].raining_change * 10000;
    }

    const [order, setOrder] = React.useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
    const [visibleRows, setVisibleRows] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);
    const [page, setPage] = React.useState(getStartingPage(order, orderBy, rows, props.stationName, rowsPerPage));

    React.useEffect(() => {
        setPage(getStartingPage(order, orderBy, rows, props.stationName, rowsPerPage))
    }, [props.stationName, rowsPerPage])

    React.useEffect(() => {
        // This function determines which rows are shown on the table
        let rowsOnMount = StableSort(
            rows,
            GetComparator(order, orderBy),
        );

        rowsOnMount = rowsOnMount.slice(
            (0 + page) * rowsPerPage,
            (0 + page) * rowsPerPage + rowsPerPage,
        );

        setVisibleRows(rowsOnMount);
    }, [props.stationName, page, order, orderBy, rowsPerPage]);

    const handleRequestSort = React.useCallback(
        (event, newOrderBy) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = StableSort(rows, GetComparator(toggledOrder, newOrderBy));
            setPage(0); // additional logic to reset page to 0
            const updatedRows = sortedRows.slice(0, rowsPerPage);

            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    const handleClick = (event, station, region) => {
        props.setStationName(station);
        props.setStationMenuName(station);
        props.setRegionName(region);
    };

    const handleChangePage = React.useCallback(
        (event, newPage) => {
            setPage(newPage);

            const sortedRows = StableSort(rows, GetComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );

            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = (33) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, rowsPerPage],
    );


    const handleChangeRowsPerPage = React.useCallback(
        (event) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);

            const sortedRows = StableSort(rows, GetComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );

            setVisibleRows(updatedRows);

            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [order, orderBy],
    );

    const isSelected = (station) => props.stationName.indexOf(station) !== -1;

    // generate 2 const with the difference between max and min for temperature_change and raining_change
    const maxTempChange = Math.max.apply(Math, rows.map(function (o) {
        return o.temperature_change;
    }))
    const minTempChange = Math.min.apply(Math, rows.map(function (o) {
        return o.temperature_change;
    }))
    const maxRainChange = Math.max.apply(Math, rows.map(function (o) {
        return o.raining_change;
    }))
    const minRainChange = Math.min.apply(Math, rows.map(function (o) {
        return o.raining_change;
    }))

    const temperatureInterval = maxTempChange - minTempChange
    const rainingInterval = maxRainChange - minRainChange

    const TableRowStyled = styled(TableRow) ({
        // Defines selected row color
        '&.Mui-selected, &.Mui-selected:hover': {
            '& .MuiTableCell-root': {
                backgroundColor: props.mode === 'light' ? lightTheme.palette.primary.light : darkTheme.palette.primary.light,
            },
        },
        })

    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2, backgroundColor:  props.mode === 'light' ? lightTheme.palette.background.tables : darkTheme.palette.background.tables}}>
                <TableContainer sx={{height: 300}}>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            setPage={setPage}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.station);
                                    const theme = props.mode === 'light' ? lightTheme : darkTheme

                                    return (
                                        <TableRowStyled
                                            className={"historical-temp-changes-table"}
                                            hover
                                            onClick={(event) => {
                                              handleClick(event, row.station, row.region);
                                              UserInteractionGA("Table Click", 'Hist. Table Click', row.station)();
                                            }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.station}
                                            selected={isItemSelected}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <TableCellStyled
                                                align="center">{index + 1 + (page * rowsPerPage)}</TableCellStyled>
                                            <TableCellStyled align="center">{row.station}</TableCellStyled>
                                            {!smDown && <TableCellStyled align="center">{row.region}</TableCellStyled>}
                                            <TableCellStyled align="center"
                                                             sx={{
                                                                 backgroundColor: row.temperature_change > 0 ?
                                                                     customRgb(theme.palette.secondary.main, row.temperature_change / temperatureInterval) :
                                                                     customRgb(theme.palette.primary.main, Math.abs(row.temperature_change) / temperatureInterval)
                                                             }}>
                                                {/* Divide by 10000 because we had multiplied before to solve some sort issues */}
                                                {row.temperature_change > 0 ? '+' + (row.temperature_change / 10000).toFixed(3) : "" + row.temperature_change / 10000}
                                            </TableCellStyled>
                                            <TableCellStyled align="center"
                                                             sx={{
                                                                 backgroundColor: row.raining_change > 0 ?
                                                                     customRgb(theme.palette.secondary.main, row.raining_change / rainingInterval) :
                                                                     customRgb(theme.palette.primary.main, Math.abs(row.raining_change) / rainingInterval)
                                                             }}>
                                                {row.raining_change > 0 ? '+' + (row.raining_change / 10000).toFixed(2) : "" + row.raining_change / 10000}
                                            </TableCellStyled>
                                        </TableRowStyled>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Nº filas:"}
                />
            </Paper>
        </Box>
    );
}
