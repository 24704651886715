export function markerFormatter(param) {
    if (param.type === "line") {
        return `<span style="display:inline-block;margin-right:4px;width:13px;height:2px;background:${param.color};vertical-align:middle;"></span>`
    } else if (param.type === "bar") {
        return `<span style="display:inline-block;margin-right:4px;width:13px;height:7px;background:${param.color};vertical-align:middle;"></span>`
    } else if (param.type === "custom") {
        return `<span style="display:inline-block;margin-right:4px;width:7.5px;height:7.5px;background:${param.color};transform:rotate(45deg);"></span>`
    } else if (param.type === "effectScatter") {
        return `<span style="display:inline-block;margin-right:4px;width:7.5px;height:7.5px;background:${param.color};border-radius:50%;"></span>`
    }
}
