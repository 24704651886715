import {Link, Typography} from "@mui/material";
import * as React from "react";

export default function Source(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Fuentes: Observaciones climáticas de la '}
            <Link color="inherit" href="https://www.aemet.es/es/portada">
                AEMET
            </Link>
        </Typography>
    );
}