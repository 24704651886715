import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {UserInteractionGA} from "../../ReactGaComponents";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

let zoomLogged = false;

function PrecZoomPlot(props) {

  const options = {
    // set background color  to transparent
    backgroundColor: "transparent",

    tooltip: { // Add the hover legend
      trigger: 'axis', // Vertical line selector
      axisPointer: {
        type: 'cross'  // Also adds the horizontal lin
      }
    },

    xAxis: {
      type: "category",
      data: props.dates,
    },

    yAxis: {
      type: "value",
      axisTick: {show: false},
      axisLine: {show: false}
    },

    grid: [
      {
        left: props.smDown ? 30 : props.mdDown ? 50 : 50,
        right: props.smDown ? 10 : props.mdDown ? 60 : 60,
        top: 0,
        height: 0,
        //bottom:100
      }
    ],

    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0],
        start: props.zoomStartDaily,
        end: props.zoomEndDaily,
        preventDefault: false, // Mobile phone fix
      }
    ]
  };

  function onDataZoom(param) {
    props.onChange(param.start, param.end);
    if (!zoomLogged) {
      UserInteractionGA("Plot Interaction", "Zoomed", "Prec. Plot")();
      zoomLogged = true;
    }
  }

  return (
    <ReactEcharts
      option={options}
      style={{height: '70px', width: '100%'}}
      theme={props.mode}
      onEvents={{
        'dataZoom': onDataZoom
      }}
    />
  );
}

export default PrecZoomPlot;
