import config from '../../Config'

function DailyApiPathGenerator(props) {
    const query = props.query
    const database = "database=" + props.database
    const collection = "collection=" + props.collection
    const max_date = "max_date=" + props.maxDate
    const fields = "fields=" + props.fields

    const getRequest = config.apiUrl + query + "/?" + database + "&" + collection + "&" + max_date + "&" + fields

    return getRequest
}

export default DailyApiPathGenerator

