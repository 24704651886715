import {styled} from "@mui/system";
import {Box} from "@mui/material";

const SizeChangingBox = styled(Box)(({theme, open}) => ({
    marginTop: 70,
    paddingTop: 20,
    paddingBottom: 20,
    width: open ? 'calc(100% - 240px)' : 'calc(100% - 72px)',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
    },
    position: 'fixed',
    zIndex: 99,
}));

export default SizeChangingBox;