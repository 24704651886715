import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import {lightTheme} from "../../../styles/lightTheme";
import {darkTheme} from "../../../styles/darkTheme";

import dark_style from "../PlotStyles/dark";
import light_style from "../PlotStyles/light";
import {TurnZoomRelative2Position} from "../../utils/TurnZoomRelative2Position";
import {max_abs, accAvg} from "../../utils/ArrayFunctions";
import useMediaQuery from "@mui/material/useMediaQuery";
import {markerFormatter} from "../../utils/MarkerFormatter";

echarts.registerTheme('dark', dark_style);
echarts.registerTheme('light', light_style);

function TempEvPlot(props) {
    const theme = props.mode === 'light' ? lightTheme : darkTheme
    const unitsString = props.smDown ? "" : " °C";

    const [startPosition, endPosition] = TurnZoomRelative2Position(
        props.zoomStartDaily,
        props.zoomEndDaily,
        props.dates.length);

    const legendShow = props.smDown
        ? ["M. mínimas", "Medias", "M. máximas"]
        : ["Medias mínimas", "Medias", "Medias máximas"];

    const legendStatus = props.smDown
        ? {"M. mínimas": false, "Medias": false, "M. máximas": false}
        : {"Medias mínimas": false, "Medias": false, "Medias máximas": false};

    function legendNameTransform(legendName) {
        const nameMap = {
            "Medias mínimas": "M. mínimas",
            "Medias": "Medias",
            "Medias máximas": "M. máximas",
        };

        const reverseNameMap = {
            "M. mínimas": "Medias mínimas",
            "Medias": "Medias",
            "M. máximas": "Medias máximas"
        };

        if (props.smDown) {
            return nameMap[legendName] || legendName;
        } else {
            return reverseNameMap[legendName] || legendName;
        }
    }
    legendStatus[legendNameTransform(props.legendName)] = true;

    function legenName2Normal(legendName) {
        const nameMap = {
            "M. mínimas": "Medias mínimas",
            "Medias": "Medias",
            "M. máximas": "Medias máximas",
            "Medias mínimas": "Medias mínimas",
            "Medias máximas": "Medias máximas"
        };
        return nameMap[legendName] || legendName;
    }


    function ComputeDeviation(props) {
        const actualData = props.actualData.map(obj => obj[props.actualDataName]);
        const refData = props.refData.map(obj => obj[props.refDataName]);

        const actualDataSliced = actualData.slice(props.startPosition, props.endPosition);
        const refDataSliced = refData.slice(props.startPosition, props.endPosition);

        const deviation = actualDataSliced.map((value, index) => value - refDataSliced[index]);
        deviation.forEach((value, index) => deviation[index] = Math.round(value * 100) / 100);

        // Make null the same values showing Null in actualData
        deviation.forEach((value, index) => {
            if (actualDataSliced[index] === null) {
                deviation[index] = null;
            }
        });
        return deviation;
    }

    // Data
    const deviationDataMin = ComputeDeviation({
        actualData: props.actualData,
        refData: props.refData,
        actualDataName: "tmin",
        refDataName: "avg_min_temp",
        startPosition: startPosition,
        endPosition: endPosition,
    })
    const AccAvgDataMin = accAvg(deviationDataMin)

    const deviationDataMed = ComputeDeviation({
        actualData: props.actualData,
        refData: props.refData,
        actualDataName: "tmed",
        refDataName: "avg_temp",
        startPosition: startPosition,
        endPosition: endPosition,
    })
    const accAvgDataMed = accAvg(deviationDataMed)

    const deviationDataMax = ComputeDeviation({
        actualData: props.actualData,
        refData: props.refData,
        actualDataName: "tmax",
        refDataName: "avg_max_temp",
        startPosition: startPosition,
        endPosition: endPosition,
    })
    const accAvgDataMax = accAvg(deviationDataMax)

    const options = {

        // set background color  to transparent
        backgroundColor: "transparent",

        tooltip: {
            // Add the hover legend
            // show: !props.smDown,
            trigger: "axis", // Vertical line selector
            axisPointer: {
                type: "cross", // Also adds the horizontal line
                label: {
                    precision: 2,
                },
            },
            formatter: function (param) {
                // initialize list with the name
                var list = param[0].axisValueLabel + '<hr size=1 style="margin: 3px 0">';
                list += markerFormatter({
                    color: param[0].color,
                    type: param[0].seriesType
                }) + "Desviación: " + param[0].value + "°C";
                list += "<br/>" + markerFormatter({
                    color: param[1].color,
                    type: param[1].seriesType
                }) + "Desv. media: " + param[1].value + "°C";
                return list;
            },
        },
        xAxis: {
            type: "category",
            data: props.dates.slice(startPosition, endPosition),
        },
        yAxis: [
            {
                type: 'value',
                name: props.smDown ? 'Desv.' : 'Desviación',
                // Title is shown on bottom of axis
                interval: 3,
                position: 'left',
                // alignTicks: true,
                axisLabel: {
                    formatter: '{value}' + unitsString
                },
                // Make the axis start and end in a multiple of 3
                min: function (value) {
                    const absValue = max_abs(value.min, value.max)
                    const absValueRounded = (absValue * -1).toFixed(0)
                    return absValueRounded - (absValueRounded % 3) - 3;
                },
                max: function (value) {
                    const absValue = max_abs(value.min, value.max)
                    const absValueRounded = (absValue).toFixed(0)
                    return absValueRounded - (absValueRounded % 3) + 3;
                },
            },
            {
                type: 'value',
                name: 'Desv. media',
                position: 'right',
                alignTicks: true,
                axisLabel: {
                    formatter: '{value}' + unitsString
                },
                min: function (value) {
                    const absValue = max_abs(value.min, value.max)
                    const absValueRounded = (absValue * -1).toFixed(0)
                    return absValueRounded - (absValueRounded % 3) - 3;
                },
                max: function (value) {
                    const absValue = max_abs(value.min, value.max)
                    const absValueRounded = (absValue).toFixed(0)
                    return absValueRounded - (absValueRounded % 3) + 3;
                },
            }
        ],
        grid: [
            {
                left: props.smDown ? 25 : props.mdDown ? 60 : 60,
                right: props.smDown ? 30 : props.mdDown ? 60 : 60,
                top: 40,
                height: 234,
            }
        ],
        series: [
            // Bars
            {
                data: deviationDataMin,
                name: props.smDown ? "M. mínimas" : "Medias mínimas",
                type: "bar",
                yAxisIndex: 0,
                smooth: true,
                color: theme.palette.plot.primary,
            },
            {
                data: deviationDataMed,
                name: "Medias",
                type: "bar",
                yAxisIndex: 0,
                smooth: true,
                color: theme.palette.plot.primary,
            },
            {
                data: deviationDataMax,
                name: props.smDown ? "M. máximas" : "Medias máximas",
                type: "bar",
                yAxisIndex: 0,
                smooth: true,
                color: theme.palette.plot.primary,
            },
            // Lines
            {
                data: accAvgDataMed,
                name: "Medias",
                type: "line",
                yAxisIndex: 1,
                smooth: true,
                symbol: 'none',
                color: theme.palette.temperatures.avg,
            },
            {
                data: AccAvgDataMin,
                name: props.smDown ? "M. mínimas" : "Medias mínimas",
                type: "line",
                yAxisIndex: 1,
                smooth: true,
                symbol: 'none',
                color: theme.palette.temperatures.avg_min,
            },
            {
                data: accAvgDataMax,
                name: props.smDown ? "M. máximas" : "Medias máximas",
                type: "line",
                yAxisIndex: 1,
                smooth: true,
                symbol: 'none',
                color: theme.palette.temperatures.avg_max,
            },

        ],
        legend: {
            selectedMode: 'single',
            data: legendShow,
            type: 'scroll',
            top: props.smDown ? 'auto' : 10,
            bottom: props.smDown ? 20 : 'auto',
            itemGap: props.smDown ? 2 : 30,
            textStyle: {
                fontSize: 12,
                fontWeight: "bold",
                fontFamily: "Roboto"
            },
            selected: legendStatus,
            icon: "none",
            inactiveColor: theme.palette.legend.inactive,
        },
    };

    function onChartReady(param) {
        //console.log('LinePlot is ready');
    }

    function onChartClick(param, echarts) {
        // console.log(param, echarts);
    }

    function onChartLegendselectchanged(param) {
        //console.log(param, echarts);
        props.setLegendName(legenName2Normal(param.name));
    };

    return (
        <ReactEcharts
            option={options}
            theme={props.mode}
            style={{height: "360px", width: "100%"}}
            onChartReady={onChartReady}
            onEvents={{
                'legendselectchanged': onChartLegendselectchanged,
                'click': onChartClick,
            }}
        />
    );
}

export default TempEvPlot;
